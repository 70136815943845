.title {
    font-weight: 700;
    font-size: var(--font-size-text3);
    line-height: 1.5em;
    color: var(--color-gray-900);
}

.budget-slider {
    margin-top: 2.5em;
    margin-bottom: 1em;

    &-mark {
        font-size: var(--font-size-text5);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--color-gray-500);
    }

    .budget-tooltip {
        font-size: var(--font-size-text5);
        font-weight: 700;
        line-height: 1.5em;
        margin-bottom: 0.5em;
        background-color: var(--color-white);
        padding: 2px;
        border: 1px solid var(--color-gray-200);
        box-shadow: var(--box-shadow-xs);
    }
}

.clear-link {
    margin-top: 6rem;
    font-weight: 600;
    font-size: var(--font-size-text4);
    line-height: 1.57em;
    color: var(--color-primary-600);
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
        filter: brightness(115%);
    }

    &-no-spacing {
        margin-top: 2rem;
    }
}
