.container {
    margin-bottom: 1.25rem;
}

.select {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    min-width: max-content;
    color: var(--color-gray-900);
    font-size: var(--font-size-text3);
    background-color: var(--color-neutral-50);
    border-radius: 0.25em;
}

.options-wrapper {
    background-color: var(--color-white);
    max-width: min(428px, max(50vw, 320px));

    .select-option-style {
        padding: 0.75em 0.5em;

        .option-item-style {
            border-radius: 0.25em;
            margin-bottom: 0.25em;
        }
    }
}

.select-control {
    background-color: var(--color-neutral-50);
    padding: 0.625rem 1rem;
    cursor: pointer;

    input {
        font-size: var(--font-size-text3);
    }
}

.empty {
    font-size: var(--font-size-text3);
    font-weight: 600;
    line-height: 1.5;
    padding: 0.5em 0.75em;
}

.footer-wrapper {
    border-top: 1px solid #d0d5dd;
    padding: 1em 1.25em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    background-color: #fff;
    min-width: max-content;
    max-width: 90vw;

    .message {
        font-size: var(--font-size-text3);
        font-weight: 500;
        line-height: 1.5;
        color: var(--color-gray-900);
    }

    .link {
        font-size: var(--font-size-text3);
        font-weight: 600;
        line-height: 1.5;
        color: var(--color-primary-600);
        cursor: pointer;
        transition: 0.2s ease-out;

        &:hover {
            filter: brightness(110%);
        }
    }
}
