.status-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .dropdown-icon {
        margin-left: auto;
        transform: rotate(-90deg);
        color: var(--color-gray-600);
        cursor: pointer;
        transition: transform 0.1s ease-out;

        &-active {
            transform: rotate(90deg);
        }
    }
}

.status-item-value {
    display: flex;
    align-items: center;
    gap: 0.375rem;

    .status-indicator {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 0.375rem;
        background-color: var(--color-primary);

        &-active {
            background-color: var(--color-warning-500);
        }

        &-completed {
            background-color: var(--color-success-500);
        }

        &-dropped {
            background-color: var(--color-error-500);
        }
    }
}

.dropdown {
    width: min(16rem, 80vw);
    box-shadow: var(--box-shadow-lg);
    padding: 0.75rem;
    margin-top: 1rem;
    z-index: 2;

    .dropdown-item {
        padding: 0.75rem;
        cursor: pointer;
        border-radius: 4px;
        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: var(--color-gray-100);
        }
    }
}
