@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap';
/* stylelint-disable-next-line */
@import '../vendor/react-arsenal/styles/_base.scss';

@font-face {
    font-family: 'Material Symbols Rounded';
    font-style: normal;
    font-display: block;
}

:global {
    .material-symbols-rounded {
        font-size: var(--font-size-heading4);
        user-select: none;
    }
}

:root {
    /* Neutral Colors */
    --color-gray-50: #f9fafb;
    --color-gray-100: #f2f4f7;
    --color-gray-200: #eaecf0;
    --color-gray-300: #d0d5dd;
    --color-gray-400: #98a2b3;
    --color-gray-500: #667085;
    --color-gray-600: #474c5a;
    --color-gray-700: #3b3e4a;
    --color-gray-800: #32343d;
    --color-gray-900: #1b1c20;

    /* Primary Colors */
    --color-primary-50: #ecf3ff;
    --color-primary-100: #dde9ff;
    --color-primary-200: #c1d7ff;
    --color-primary-400: #7594ff;
    --color-primary-600: #4a54d3;
    --color-primary-800: #323aa0;
    --color-primary-900: #212660;

    /* Secondary Colors */
    --color-secondary-50: #fff1f3;
    --color-secondary-100: #ffe4e8;
    --color-secondary-200: #ffccd7;
    --color-secondary-400: #fd6f8f;
    --color-secondary-600: #d13764;
    --color-secondary-800: #982a4a;
    --color-secondary-900: #782645;

    /* Success Colors */
    --color-success-300: #6fe6a7;
    --color-success-500: #12b76a;
    --color-success-700: #057747;

    /* Warning Colors */
    --color-warning-300: #fdc94c;
    --color-warning-500: #f79009;
    --color-warning-700: #b54808;

    /* Error Colors */
    --color-error-300: #fdaaa4;
    --color-error-500: #f04438;
    --color-error-700: #bb241a;

    /* Map Colors */
    --color-map-200: #d6dff0;
    --color-map-300: #c0c8df;
    --color-map-400: #acb8cf;
    --color-map-500: #9eacc5;
    --color-map-600: #7d8eac;
    --color-map-700: #566a8e;
    --color-map-800: #203864;

    /* Misc Colors */
    --color-primary: #4a54d3;
    --color-border: #d0d5dd;
    --color-input-outline: #7594ff;
    --color-danger: #f04438;

    /* Font Sizes */
    --font-size-display1: 4.5rem; // 72px
    --font-size-display2: 3.75rem; // 60px
    --font-size-heading1: 3rem; // 48px
    --font-size-heading2: 2.25rem; // 36px
    --font-size-heading3: 1.875rem; // 30px
    --font-size-heading4: 1.5rem; // 24px
    --font-size-text1: 1.25rem; // 20px
    --font-size-text2: 1.125rem; // 18px
    --font-size-text3: 1rem; // 16px
    --font-size-text4: 0.875rem; // 14px
    --font-size-text5: 0.75rem; // 12px
    --font-size-normal-lg: 1.375rem; // 22px

    /* Shadows */
    --box-shadow-xs: 0 1px 2px rgb(16 24 40 / 5%);
    --box-shadow-sm: 0 1px 3px rgb(16 24 40 / 10%), 0 1px 2px rgb(16 24 40 / 6%);
    --box-shadow-md: 0 4px 8px -2px rgb(16 24 40 / 10%), 0 2px 4px -2px rgb(16 24 40 / 6%);
    --box-shadow-lg: 0 4px 6px -2px rgb(16 24 40 / 3%), 0 12px 16px -4px rgb(16 24 40 / 8%);
    --box-shadow-xl: 0 8px 8px -4px rgb(16 24 40 / 3%), 0 20px 24px -4px rgb(16 24 40 / 8%);
}

* {
    margin: 0;
    padding: 0;
    font-family: Manrope, 'open_sauce_sans', serif;

    @media only screen and (max-width: 991px) {
        font-size: 14px;
    }

    @media only screen and (max-width: 719px) {
        font-size: 13px;
    }

    @media only screen and (max-width: 479px) {
        font-size: 12px;
    }
}

input[type='radio'],
input[type='checkbox'] {
    height: 1.25rem;
    width: 1.25rem;
    accent-color: var(--color-primary-600);
}

a {
    text-decoration: none;
}
