.hazard-item {
    background-color: var(--color-gray-100);
    border-radius: 3rem;
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease-out;
    min-width: fit-content;

    &:hover:not(.hazard-item-active) {
        background-color: var(--color-gray-200);
    }

    &-icon {
        width: 1rem;
        height: 1rem;

        path {
            fill: var(--color-gray-400);
        }
    }

    .hazard-item-icon-active {
        path {
            fill: var(--color-primary-600);
        }
    }

    &-text {
        font-size: var(--font-size-text5);
        font-weight: 600;
        line-height: 1.5em;
        margin-left: 0.5rem;
        user-select: none;
    }

    &-active {
        background-color: var(--color-primary-100);

        .hazard-item-text {
            color: var(--color-primary-600);
        }
    }

    &-disabled {
        cursor: unset;

        &:hover:not(.hazard-item-active) {
            background-color: var(--color-gray-100);
        }
    }
}

.container {
    display: flex;
    flex-direction: column;

    .header {
        padding: 0.5rem 0.75rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: var(--font-size-text4);
            line-height: 1.57em;
            font-weight: 700;
            color: var(--color-gray-900);
        }

        .clear-link {
            font-size: var(--font-size-text4);
            font-weight: 500;
            line-height: 1.5em;
            color: var(--color-primary-600);
            transition: filter 0.2s ease-out;
            cursor: pointer;

            &:hover {
                filter: brightness(105%);
            }
        }
    }
}
