.container {
    .header {
        display: flex;
        align-items: center;
        background-color: var(--color-gray-50);
        border: 1px solid var(--color-gray-300);
        border-radius: 4px 4px 0 0;
        padding: 0.5rem 0;

        .header-item {
            padding: 0 1rem;
            font-size: var(--font-size-text5);
            font-weight: 600;
            color: var(--color-gray-800);
            line-height: 1.5em;

            &:first-child {
                flex: 0 0 5%;
            }

            &:last-child {
                flex: 0 0 12%;
            }

            &:nth-child(3) {
                flex: 0 0 7rem;
            }

            &:nth-child(2) {
                flex: 1;
            }
        }
    }

    .body-row {
        display: flex;
        align-items: center;
        background-color: var(--color-white);
        padding: 1rem 0;
        padding-left: 1rem;
        border-color: var(--color-gray-300);
        border-width: 0 1px 1px;
        border-style: solid;
        border-radius: 0 0 4px 4px;

        .body-row-item {
            padding-right: 1rem;

            &:first-child {
                flex: 0 0 5%;
            }

            &:last-child {
                flex: 0 0 12%;
            }

            &:nth-child(3) {
                flex: 0 0 7rem;
            }

            &:nth-child(2) {
                flex: 1;
            }

            .number {
                font-size: var(--font-size-text4);
                font-weight: 700;
                line-height: 1.57em;
                color: var(--color-gray-800);
            }

            .add-button {
                display: flex;
                align-items: center;
                gap: 0.25rem;
                cursor: pointer;
                color: var(--color-primary-600);
                font-weight: 500;
                font-size: var(--font-size-text4);
                line-height: 1.57em;
                transition: color 0.3s ease-out;

                .add-icon {
                    font-size: var(--font-size-text2);
                }

                &:hover {
                    color: var(--color-primary-800);
                }
            }

            .remove-icon-container {
                display: flex;
                align-items: center;
                justify-content: center;

                .remove-icon {
                    text-align: center;
                    color: var(--color-gray-500);
                    cursor: pointer;
                    transition: color 0.3s ease-out;

                    &:hover {
                        color: var(--color-gray-600);
                    }
                }
            }

            .target-input {
                font-size: var(--font-size-text5);
            }
        }
    }
}
