$sidebar-width: 16rem;
$sidebar-width-collapsed: 7rem;
$sidebar-width-collapsed-mobile: 5rem;

.container {
    width: 100vw;
    height: 100vh;

    @media screen and (max-width: 480px) {
        overflow-x: hidden;
    }

    .overlay {
        display: none;

        @media screen and (max-width: 480px) {
            display: block;
            width: 100vw;
            height: 100vh;
            background-color: rgb(0 0 0);
            opacity: 0.75;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9;
            animation: overlay-fade-in 0.3s ease-out;
        }
    }

    .side-bar {
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--color-white);
        border-right: 1px solid var(--color-gray-200);
        height: 100vh;
        width: $sidebar-width;
        padding: 1rem 1.25rem;
        transition: 0.3s ease-out;
        display: flex;
        flex-direction: column;
        z-index: 10;

        @media screen and (max-width: 480px) {
            padding: 1rem 0.5rem;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hamburger-menu {
                cursor: pointer;
                transition: 0.2s ease-out;
                border: 1px solid var(--color-white);
                display: flex;
                align-items: center;
                border-radius: 4px;
                padding: 0.125em;

                &:hover {
                    border: 1px solid var(--color-gray-300);
                    box-shadow: var(--box-shadow-md);
                    filter: brightness(110%);
                }

                &-collapsed {
                    padding: 0.5em;
                }
            }

            .side-bar-logo {
                width: 10rem;
                user-select: none;

                &-uncollapsed {
                    width: 10rem;
                    opacity: 0;
                    animation: fade-in 0.3s 0.2s ease-out forwards;
                }
            }
        }

        .menu {
            margin-top: 1rem;
            padding-top: 3.5rem;
            margin-bottom: auto;
            overflow-y: auto;
            overflow-x: hidden;

            .menu-item {
                font-size: var(--font-size-text3);
                padding: 0.75em;
                color: var(--color-gray-500);
                line-height: 1.5em;
                display: flex;
                align-items: center;
                gap: 0.625em;
                border-radius: 4px;
                transition: background-color 0.2s ease-out;
                margin-bottom: 0.25rem;

                &:hover,
                &:focus {
                    background-color: var(--color-gray-50);
                }

                .menu-item-label {
                    font-weight: 500;
                }

                &-active,
                &-active:hover,
                &-active:focus {
                    color: var(--color-primary-600);
                    background-color: var(--color-primary-50);

                    .menu-item-label {
                        font-weight: 600;
                    }
                }

                &-collapsed {
                    align-self: flex-start;
                }

                .menu-item-text {
                    &-uncollapsed {
                        opacity: 0;
                        animation: fade-in 0.3s 0.2s ease-out forwards;
                    }
                }
            }
        }

        &-collapsed {
            width: $sidebar-width-collapsed;
            align-items: center;

            @media screen and (max-width: 480px) {
                width: $sidebar-width-collapsed-mobile;
                z-index: 4;
            }

            .header {
                align-self: center;
            }
        }
    }

    .nav-bar {
        position: fixed;
        width: calc(100% - $sidebar-width);
        margin-left: $sidebar-width;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem 1.25rem;
        background-color: var(--color-white);
        border-bottom: 1px solid var(--color-gray-200);
        gap: 0.5em;
        transition: 0.3s ease-out;
        flex-wrap: wrap;
        overflow-x: hidden;
        z-index: 8;

        &-sticky {
            position: sticky;
        }

        @media screen and (max-width: 991px) {
            flex-direction: column-reverse;
        }

        @media screen and (max-width: 480px) {
            width: calc(100% - $sidebar-width-collapsed-mobile);
            margin-left: $sidebar-width-collapsed-mobile;
        }

        .nav-bar-logo {
            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        &-collapsed {
            margin-left: $sidebar-width-collapsed;
            width: calc(100% - $sidebar-width-collapsed);

            .nav-bar-logo {
                width: 10rem;
                margin-right: 1em;
                user-select: none;
            }

            @media screen and (max-width: 480px) {
                width: calc(100% - $sidebar-width-collapsed-mobile);
                margin-left: $sidebar-width-collapsed-mobile;
            }
        }

        .breadcrumbs {
            margin-right: auto;

            .breadcrumbs-link {
                &-disabled {
                    pointer-events: none;
                }

                .breadcrumb-item {
                    font-size: var(--font-size-text3);
                    font-weight: 600;
                    line-height: 1.5em;
                    color: var(--color-gray-800);

                    &-active {
                        color: var(--color-gray-400);
                    }
                }
            }
        }

        .navbar-right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 1.5em;

            @media screen and (max-width: 991px) {
                margin-right: 1.25rem;
                width: 100%;
            }

            @media screen and (max-width: 767px) {
                gap: 0.5em;
            }

            .search-input {
                width: fit-content;
                margin-left: auto;
                border-radius: 50%;
                background-color: var(--color-gray-100);
                padding: 0.5rem;
            }

            .control-icons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1.5em;

                .button {
                    min-width: max-content;
                }

                @media screen and (max-width: 991px) {
                    gap: 0.5em;
                }

                @media screen and (max-width: 767px) {
                    width: 100%;
                    justify-content: flex-end;
                }
            }
        }
    }

    .content-container {
        margin-left: $sidebar-width;
        transition: 0.3s ease-out;
        width: calc(100vw - $sidebar-width);
        background-color: var(--color-gray-100);
        height: 100%;

        @media screen and (max-width: 480px) {
            margin-left: $sidebar-width-collapsed-mobile;
            width: calc(100vw - $sidebar-width-collapsed-mobile);
        }

        &-collapsed {
            margin-left: $sidebar-width-collapsed;
            width: calc(100vw - $sidebar-width-collapsed);

            @media screen and (max-width: 480px) {
                margin-left: $sidebar-width-collapsed-mobile;
                width: calc(100vw - $sidebar-width-collapsed-mobile);
            }
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes overlay-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.75;
    }
}
