.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;

    .separator {
        display: flex;
        align-items: center;
        color: var(--color-gray-400);
        user-select: none;
    }
}
