.container {
    padding: 1.625rem 1.25rem;
    position: relative;
    height: 100%;
    overflow: hidden;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        flex-flow: wrap;
        gap: 0.75rem;

        .header-left-content {
            display: flex;
            align-items: center;
            flex-flow: wrap;
            gap: 1.25rem;

            .title {
                color: var(--color-gray-900);
                font-size: var(--font-size-heading4);
                font-weight: 700;
                line-height: 1.17em;
                letter-spacing: -0.02em;
            }

            .tab-header-item {
                font-size: var(--font-size-text4);
                font-weight: 500;
                background-color: var(--color-gray-100);
                color: var(--color-gray-500);

                &-active {
                    font-weight: 600;
                    background-color: var(--color-primary-50);
                    color: var(--color-primary-600);
                }
            }
        }

        .buttons {
            display: flex;
            gap: 0.75em;

            .clear-link {
                align-self: center;
                font-weight: 500;
                font-size: var(--font-size-text4);
                color: var(--color-primary-600);
                line-height: 1.57em;
                cursor: pointer;
            }

            .filter-button-active {
                background-color: var(--color-primary-100);
            }
        }
    }

    .filters {
        margin-bottom: 1rem;
        opacity: 0;
        animation: fade-in 0.3s ease-out forwards;
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .date-filter-container {
            max-width: max-content;
            width: fit-content;

            .date-popper {
                z-index: 5;
            }

            :global {
                .react-datepicker__close-icon::after {
                    background-color: var(--color-gray-500);
                }

                .react-datepicker__day--in-range,
                .react-datepicker__day--keyboard-selected {
                    background-color: var(--color-primary);
                }

                .react-datepicker__day--in-selecting-range:not(
                        .react-datepicker__day--in-range,
                        .react-datepicker__day--keyboard-selected
                    ) {
                    background-color: var(--color-primary-400);
                }
            }
        }
    }

    .table-container {
        overflow: auto;
        border: 1px solid var(--color-gray-300);
        border-radius: 4px;
        box-shadow: var(--box-shadow-xs);

        .table {
            .table-header {
                position: sticky;
                top: 0;

                &-static {
                    position: static;
                }
            }
        }
    }

    .footer {
        position: absolute;
        background-color: var(--color-gray-100);
        z-index: 2;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1.25rem;

        .max-rows-select {
            display: flex;
            align-items: center;
            font-size: var(--font-size-text4);
            font-weight: 600;
            color: var(--color-gray-600);

            .select {
                color: var(--color-gray-500);
                font-size: var(--font-size-text4);
                line-height: 1.57em;
                font-weight: 500;
                margin: 0 0.75rem;
                background-color: var(--color-white);
                border-color: var(--color-gray-300);
                border-radius: 4px;

                &__control {
                    min-width: 50px;
                    height: 35px;
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .pagination {
            box-shadow: none;

            .pagination-item {
                margin: 0 0.25rem;
                border: 1px solid transparent;
                padding: 0.25em;
                width: 1.75rem;
                height: 1.75rem;
                font-size: var(-font-size-text3);
                border-radius: 50%;
                color: var(--color-gray-800);
                font-weight: 600;
                transition: 0.2s ease-out;

                &-active,
                &:hover:not(.pagination-control) {
                    border: 1px solid var(--color-primary);
                    color: var(--color-gray-50);
                }
            }

            &-control {
                display: flex;
                align-items: center;
                background-color: var(--color-white);
                border: 1px solid var(--color-gray-300);
                border-radius: 4px;
                margin: 0 0.25em;
                transition: 0.2s ease-out;

                span {
                    font-size: var(--font-size-text1);
                    line-height: 1em;
                    color: var(--color-gray-500);
                }

                &:hover {
                    background-color: var(--color-gray-50);
                    filter: brightness(95%);

                    span {
                        color: var(--color-gray-500);
                    }
                }
            }
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.filter-list {
    background-color: var(--color-white);
    padding: 1rem 2.5rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.filter-item-list {
    padding: 0.5rem;
    background-color: var(--color-white);
    width: 33vw;
    max-width: 300px;
    min-width: fit-content;
    display: flex;
    flex-flow: wrap;
    gap: 0.5rem;
}

.filter-budget {
    width: 50vw;
    max-width: 320px;
    padding: 1rem;
    min-height: 8rem;

    &-active {
        min-height: 9rem;
    }
}
