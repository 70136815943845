.project-checklist-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-text {
        font-weight: 700;
        font-size: var(--font-size-text5);
        line-height: 1.5em;
        color: var(--color-gray-600);
        cursor: pointer;
        user-select: none;
    }

    &-icon {
        margin-left: auto;
        color: var(--color-gray-500);
        font-size: var(--font-size-text2);
        cursor: pointer;
    }

    &-long {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.5rem;
    }
}

.district-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0.75rem 0 0.125rem 1.5rem;
    opacity: 0;
    animation: fade-in 0.3s ease-in forwards;

    .sub-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        &-label {
            margin-left: 0.5rem;
            color: var(--color-gray-600);
            font-size: var(--font-size-text5);
            line-height: 1.5em;
            cursor: pointer;
            user-select: none;
        }
    }

    &-hidden {
        display: none;
    }
}

.checkbox {
    border-color: var(--color-gray-400);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
