.label-button {
    box-shadow: var(--box-shadow-xs);
    padding: 0.45em 0.75em;

    .icon {
        font-size: var(--font-size-heading4);
    }

    &-active {
        border: 1px solid var(--color-primary-400);
        background-color: var(--color-gray-50);
    }

    &-filled {
        color: var(--color-gray-900);

        .icon {
            color: var(--color-gray-500);
        }
    }
}

.dropdown {
    box-shadow: var(--box-shadow-xl);
    border: 1px solid var(--color-gray-100);
    border-radius: 4px;
    max-height: 50vh;
    overflow-y: auto;
}
