.map-container {
    position: relative;

    .legend {
        position: absolute;
        left: 2em;
        bottom: 2.5em;
    }

    .overview {
        position: absolute;
        top: 1em;
        left: 2em;
    }

    .popup {
        display: flex;
        flex-direction: column;
        min-width: 10rem;

        .feature-title {
            font-size: var(--font-size-text3);
            font-weight: 700;
            line-height: 1.5em;
            color: var(--color-gray-800);
            margin-bottom: 0.5rem;
        }

        .feature-info-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            .info-key {
                font-weight: 500;
                font-size: var(--font-size-text5);
                line-height: 1.5em;
                color: var(--color-gray-500);
                margin-right: 1em;
            }

            .info-value {
                font-size: var(--font-size-text5);
                line-height: 1.5em;
                color: var(--color-gray-800);
                font-weight: 700;
            }

            &:last-child {
                margin-bottom: 0.75rem;
            }
        }
    }

    .loaderOverlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(0 0 0 / 60%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
