.modal {
    background-color: var(--color-white);
    width: 27.75em;
    max-width: 90%;
    box-shadow: var(--box-shadow-xl);
    border-radius: 0.5em;
    padding: 2em;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25em;

        .lock-wrapper {
            height: 2.5em;
            width: 2.5em;
            border-radius: 50%;
            background-color: var(--color-primary-100);
            display: flex;
            justify-content: center;
            align-items: center;

            .lock-icon {
                color: var(--color-primary-600);
                font-size: var(--font-size-heading4);
            }
        }

        .close-icon {
            cursor: pointer;
            color: var(--color-gray-400);
            font-size: var(--font-size-heading4);
        }
    }

    .title {
        font-size: var(--font-size-text1);
        line-height: 2em;
        color: var(--color-gray-900);
    }

    .description {
        font-size: var(--font-size-text3);
        line-height: 1.5em;
        color: #585d69;
        margin-bottom: 1.25em;
    }

    .form-wrapper {
        .error-message {
            color: var(--color-danger);
            font-size: var(--font-size-text4);
            letter-spacing: 0.3px;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        .input-label {
            font-size: var(--font-size-text4);
            color: var(--color-gray-600);
            font-weight: 500;
        }

        .button {
            margin: 2em 0;
            width: 100%;

            &-single {
                margin: 0;
            }
        }
    }

    .back-icon {
        display: flex;
        align-items: center;
        gap: 0.5em;
        width: fit-content;
        cursor: pointer;

        .back-icon {
            font-size: var(--font-size-text1);
            height: fit-content;
            color: var(--color-primary-600);
        }

        .back-title {
            font-size: var(--font-size-text3);
            line-height: 1.5;
            color: var(--color-primary-600);
            font-weight: 600;
        }
    }
}
