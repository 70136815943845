.project-documents-container {
    display: grid;
    width: 100%;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, 22rem);
    gap: 1rem;
    align-items: flex-start;

    .project-document {
        max-width: 22rem;
        flex: 1;
        padding: 1.25rem 1rem 0.75rem;
        border: 1px solid var(--color-gray-200);
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        margin-top: 1rem;

        .project-document-title {
            font-size: var(--font-size-text4);
            line-height: 1.57em;
            color: var(--color-gray-800);
            padding: 0.25rem 0.5rem;
            background-color: var(--color-white);
            margin-top: -2.25rem;
            margin-bottom: 0.325rem;
            width: fit-content;
        }

        .project-document-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;

            .project-document-icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem;
                border-radius: 8px;
                background-color: var(--color-primary-50);

                .project-document-icon {
                    font-size: var(--font-size-text1);
                    color: var(--color-primary-600);
                }
            }

            .project-document-name {
                flex: 1;
                font-size: var(--font-size-text4);
                line-height: 1.57em;
                color: var(--color-gray-500);
            }

            .project-visibility-icon {
                font-size: var(--font-size-text2);
                color: var(--color-primary-600);
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 0.25rem;
                }
            }
        }

        .project-document-remarks {
            font-size: var(--font-size-text4);
            color: var(--color-gray-600);
            line-height: 1.57em;
            margin-top: 0.75rem;
        }
    }
}

.empty-message {
    font-size: var(--font-size-text4);
    color: var(--color-gray-600);
}
