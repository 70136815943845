.container {
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    padding: 0.125em;
    gap: 0.5rem;
    transition: 0.2s ease-out;
    min-width: fit-content;

    &:hover {
        background-color: var(--color-gray-100);
    }

    .avatar {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
    }

    .user-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        user-select: none;

        .user-name {
            font-size: var(--font-size-text4);
            font-weight: 600;
            line-height: 1.57em;
            color: var(--color-gray-900);
            text-transform: capitalize;
        }

        .organization {
            font-size: var(--font-size-text5);
            font-weight: 500;
            line-height: 1.5em;
            color: var(--color-gray-500);
        }
    }
}

.dropdown {
    box-shadow: var(--box-shadow-lg);
    border-radius: 4px;

    .dropdown-list {
        border: 1px solid var(--color-gray-100);
        margin-top: 0.375em;
        padding: 0.75em 0.5em;
        list-style: none;
        background-color: var(--color-white);
        min-width: 200px;
        border-radius: 4px;
    }

    .dropdown-item {
        padding: 0.625em 0.75em;
        cursor: pointer;
        border-radius: 4px;
        margin-bottom: 0.25em;
        color: var(--color-gray-900);
        display: flex;
        align-items: center;
        gap: 0.625rem;
        min-width: max-content;
        width: 100%;

        &:hover {
            background-color: var(--color-gray-100);
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-icon {
            color: var(--color-gray-500);
        }
    }
}
