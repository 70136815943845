.form-section {
    &:not(:last-child) {
        margin-bottom: 2.5rem;
    }

    .form-section-title {
        border-bottom: 1px solid var(--color-gray-200);
        padding: 0.75rem 0;
        margin-bottom: 1rem;
        color: var(--color-gray-600);
        font-size: var(--font-size-text3);
        line-height: 1.5em;
        text-transform: uppercase;
    }

    .input-label {
        font-size: var(--font-size-text4);
        font-weight: 500;
        line-height: 1.57;
        text-align: left;
        color: var(--color-gray-600);
        margin-bottom: 0.25em;
    }

    .textarea {
        padding: 0.625em 1em;
        font-size: var(--font-size-text3);
        line-height: 1.5em;
        color: var(--color-gray-900);
        width: 100%;
        border: 1px solid var(--color-border);
        background-color: inherit;
        border-radius: var(--border-radius-small);

        &::placeholder {
            color: var(--color-gray-400);
        }

        &:-ms-input-placeholder {
            color: var(--color-gray-400);
        }

        &::-ms-input-placeholder {
            color: var(--color-gray-400);
        }

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            border: 1px solid var(--color-input-outline);
            outline-color: var(--color-input-outline);
        }
    }
}

.input-spacing {
    margin-bottom: 1.25em;
}

.document-inputs {
    padding-left: 1.25rem;
}

.continue-button {
    margin-left: auto;
    padding: 0.75rem 2rem;
}

.input-title {
    font-size: var(--font-size-text4);
    line-height: 1.57em;
    font-weight: 500;
    color: var(--color-gray-800);
    margin-bottom: 0.25rem;
}

.location-input-container {
    padding: 1rem 1.25rem 0;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-50);
    border-radius: 4px;
    margin-bottom: 1.25rem;
}
