.modal {
    border-radius: 8px;
    width: 50rem;
    max-width: 90vw;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-xl);
    padding: 1.25rem 1rem;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 2rem;
        padding-right: 0.25rem;

        .title {
            font-size: var(--font-size-heading3);
            font-weight: 700;
            line-height: 1.27em;
            letter-spacing: -0.02em;
            color: var(--color-gray-800);
            width: 90%;
        }

        .close-icon {
            color: var(--color-gray-400);
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
                color: var(--color-gray-500);
            }
        }
    }

    .content {
        max-height: 70vh;
        overflow-y: auto;
        position: relative;

        .description {
            margin-bottom: 2.5rem;
        }

        .tab-header {
            position: sticky;
            top: 0;
            box-shadow: unset;
            border-radius: unset;
            padding: 1rem 0;
            border-bottom: 1px solid var(--color-gray-200);
        }

        .tab-content-container {
            padding-top: 1.25rem;

            .content-title {
                font-size: var(--font-size-text3);
                line-height: 1.5em;
                letter-spacing: 0.04em;
                font-weight: 700;
                color: var(--color-gray-500);
                margin-bottom: 1rem;
            }

            .content-row {
                display: flex;
                flex-flow: wrap;
                gap: 1rem;
                margin-bottom: 2.25rem;

                .info-item-container {
                    flex: 1;
                    display: flex;
                    align-items: flex-start;
                    border: 1px solid var(--color-gray-200);
                    border-radius: 4px;
                    padding: 1rem;
                    width: fit-content;
                    min-width: 19rem;
                    gap: 1rem;

                    .info-item-content {
                        flex-direction: column;

                        &-key {
                            font-size: var(--font-size-text4);
                            line-height: 1.57em;
                            color: var(--color-gray-500);
                            font-weight: 600;
                            margin-bottom: 0.5rem;
                        }

                        &-value {
                            font-size: var(--font-size-text3);
                            line-height: 1.5em;
                            color: var(--color-gray-900);
                            font-weight: 700;
                        }

                        &-footer {
                            margin-top: 0.75rem;
                        }

                        .exchange-rate {
                            background-color: var(--color-gray-100);
                            border-radius: 4px;
                            color: var(--color-gray-800);
                            font-weight: 500;
                            font-size: var(--font-size-text4);
                            line-height: 1.57em;
                            padding: 0.5rem;
                            margin-top: 0.5rem;
                        }
                    }
                }
            }
        }

        .content-item {
            border: 1px solid var(--color-gray-200);
            border-radius: 4px;
            padding: 1rem;
            margin-bottom: 1rem;

            &-title {
                font-size: var(--font-size-text3);
                margin-bottom: 0.5rem;
            }

            &-value {
                font-size: var(--font-size-text3);
                line-height: 1.5em;
                color: var(--color-gray-900);
                font-weight: 700;
                margin-bottom: 0.75rem;
            }
        }
    }
}
