.container {
    height: 100vh;
    background-color: var(--color-gray-100);
    display: flex;
    flex-direction: column;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: wrap-reverse;
        height: 100%;

        .info-section {
            .title {
                font-size: var(--font-size-display1);
                letter-spacing: -0.02em;
                line-height: 1.25em;
                font-weight: 700;
                color: var(--color-gray-600);
                margin-bottom: 1.25rem;
            }

            .sub-title {
                font-size: var(--font-size-heading1);
                letter-spacing: -0.02em;
                font-weight: 600;
                line-height: 1.25em;
                color: var(--color-gray-600);
                margin-bottom: 1.875rem;
            }

            .description {
                font-size: var(--font-size-text1);
                line-height: 1.6em;
                color: var(--color-gray-500);
            }

            .home-link {
                margin-top: 2.25rem;
                max-width: fit-content;
            }
        }

        .error-image {
            max-width: min(50vw, 37rem);
        }
    }
}
