.project-status-item {
    border: 1px solid var(--color-gray-200);
    border-radius: 4px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover:not(.project-status-item-active) {
        background-color: var(--color-gray-50);
    }

    &-input[type='checkbox'] {
        width: 1em;
        height: 1em;
    }

    &-text {
        color: var(--color-gray-800);
        font-weight: 600;
        font-size: var(--font-size-text5);
        line-height: 1.5em;
        margin-left: 0.5rem;
    }

    &-active {
        background-color: var(--color-primary-50);
        border-color: var(--color-primary-50);
    }
}
