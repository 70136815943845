.content-container {
    position: relative;

    .container {
        scroll-behavior: smooth;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .left-arrow {
            top: 0;
            bottom: 0;
            position: absolute;
            left: 0;
            background: linear-gradient(
                to right,
                #fff 0%,
                rgb(255 255 255 / 88%) 47.4%,
                rgb(255 255 255 / 0%) 100%
            );
            width: 5.75rem;
            display: flex;
            align-items: center;
            animation: fade-in 0.5s ease;
            z-index: 7;

            &-content {
                background-color: var(--color-white);
                box-shadow: var(--box-shadow-sm);
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                transition: 0.2s ease-out;
                cursor: pointer;

                &:hover {
                    background-color: var(--color-gray-100);
                }
            }
        }

        .right-arrow {
            top: 0;
            bottom: 0;
            position: absolute;
            right: 0;
            width: 5.75rem;
            background: linear-gradient(
                to left,
                #fff 0%,
                rgb(255 255 255 / 88%) 47.4%,
                rgb(255 255 255 / 0%) 100%
            );
            display: flex;
            align-items: center;
            justify-content: flex-end;
            animation: fade-in 0.5s ease;
            z-index: 7;

            &-content {
                background-color: var(--color-white);
                box-shadow: var(--box-shadow-sm);
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                transition: 0.2s ease-out;
                cursor: pointer;

                &:hover {
                    background-color: var(--color-gray-100);
                }
            }
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
