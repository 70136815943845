.button {
    padding: 0.45em 0.75em;

    .icon {
        font-size: var(--font-size-heading4);
        color: var(--color-gray-500);
    }

    &-padded {
        color: var(--color-gray-900);
        padding-right: 2rem;
    }
}
