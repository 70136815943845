$padding-inline: 1.25em;

.container {
    position: relative;
    height: 100%;
    overflow: hidden;

    .header {
        background-color: var(--color-primary-600);
        padding: 0 $padding-inline;

        @media screen and (min-width: 992px) {
            background: url('~assets/shapes/rectangle_sm_up.svg') 75% 0% no-repeat,
                url('~assets/shapes/rectangle_med_down.svg') 100% 100% no-repeat,
                url('~assets/shapes/circle_sm.svg') 88% 37% no-repeat,
                url('~assets/shapes/circle_lg_qtr.svg') 100% 0 no-repeat;
            background-color: var(--color-primary-600);
        }

        .activity-title {
            max-width: 750px;
            width: 75%;
            padding: 2.625rem 0;
            color: var(--color-white);
            font-size: var(--font-size-heading3);
            letter-spacing: -0.02em;
            line-height: 1.27em;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }

    .content {
        padding: 1.5rem $padding-inline 0;

        .content-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.25rem;
            flex-flow: wrap;
            gap: 0.75rem;

            .title {
                color: var(--color-gray-900);
                font-size: var(--font-size-text2);
                font-weight: 700;
                line-height: 1.56em;
            }

            .buttons {
                display: flex;
                gap: 0.75em;

                .clear-link {
                    align-self: center;
                    font-weight: 500;
                    font-size: var(--font-size-text4);
                    color: var(--color-primary-600);
                    line-height: 1.57em;
                    cursor: pointer;
                }

                .filter-button-active {
                    background-color: var(--color-primary-100);
                }
            }
        }

        .filters {
            margin-bottom: 1rem;
            opacity: 0;
            animation: fade-in 0.3s ease-out forwards;
            display: flex;
            align-items: center;
            gap: 0.75rem;

            .date-filter-container {
                max-width: max-content;
                width: fit-content;

                .date-popper {
                    z-index: 5;
                }

                :global {
                    .react-datepicker__close-icon::after {
                        background-color: var(--color-gray-500);
                    }

                    .react-datepicker__day--in-range,
                    .react-datepicker__day--keyboard-selected {
                        background-color: var(--color-primary);
                    }

                    .react-datepicker__day--in-selecting-range:not(
                            .react-datepicker__day--in-range,
                            .react-datepicker__day--keyboard-selected
                        ) {
                        background-color: var(--color-primary-400);
                    }
                }
            }
        }

        .table-container {
            overflow: auto;
            border: 1px solid var(--color-gray-300);
            border-radius: 4px;

            .table-header {
                position: sticky;
                top: 0;

                &-static {
                    position: static;
                }
            }
        }
    }

    .footer {
        position: absolute;
        background-color: var(--color-gray-100);
        z-index: 2;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1.25rem;

        .max-rows-select {
            display: flex;
            align-items: center;
            font-size: var(--font-size-text4);
            font-weight: 600;
            color: var(--color-gray-600);

            .select {
                color: var(--color-gray-500);
                font-size: var(--font-size-text4);
                line-height: 1.57em;
                font-weight: 500;
                margin: 0 0.75rem;
                background-color: var(--color-white);
                border-color: var(--color-gray-300);
                border-radius: 4px;

                &__control {
                    min-width: 50px;
                    height: 35px;
                    background-color: transparent;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .pagination {
            box-shadow: none;

            .pagination-item {
                margin: 0 0.25rem;
                border: 1px solid transparent;
                padding: 0.25em;
                width: 1.75rem;
                height: 1.75rem;
                font-size: var(-font-size-text3);
                border-radius: 50%;
                color: var(--color-gray-800);
                font-weight: 600;
                transition: 0.2s ease-out;

                &-active,
                &:hover:not(.pagination-control) {
                    border: 1px solid var(--color-primary);
                    color: var(--color-gray-50);
                }
            }

            &-control {
                display: flex;
                align-items: center;
                background-color: var(--color-white);
                border: 1px solid var(--color-gray-300);
                border-radius: 4px;
                margin: 0 0.25em;
                transition: 0.2s ease-out;

                span {
                    font-size: var(--font-size-text1);
                    line-height: 1em;
                    color: var(--color-gray-500);
                }

                &:hover {
                    background-color: var(--color-gray-50);
                    filter: brightness(95%);

                    span {
                        color: var(--color-gray-500);
                    }
                }
            }
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.filter-list {
    background-color: var(--color-white);
    padding: 1rem 2.5rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.filter-item-list {
    padding: 0.5rem;
    background-color: var(--color-white);
    width: 33vw;
    max-width: 300px;
    min-width: fit-content;
    display: flex;
    flex-flow: wrap;
    gap: 0.5rem;
}

.filter-budget {
    width: 50vw;
    max-width: 320px;
    padding: 1rem;
    min-height: 8rem;

    &-active {
        min-height: 9rem;
    }
}
