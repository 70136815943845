.nav-bar-container {
    box-shadow: var(--box-shadow-xs);
    background-color: var(--color-white);

    .nav-bar {
        display: flex;
        align-items: center;
        padding: 1.25rem 0;
        justify-content: space-between;

        @media screen and (max-width: 567px) {
            padding: 0.5rem 0;
            flex-direction: column;
            align-items: stretch;
        }

        .nav-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;

            .nav-brand {
                .logo {
                    width: 20em;
                }
            }

            .nav-menu {
                margin-left: auto;
                display: flex;
                align-items: center;

                .nav-links {
                    margin-left: auto;
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 992px) {
                        display: none;
                    }

                    .menu-link {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-right: 1.375em;
                        transition: 0.3s ease;

                        @media screen and (max-width: 1123px) {
                            margin-right: 0.75em;
                        }

                        .menu-link-text {
                            color: var(--color-gray-800);
                            font-size: var(--font-size-text3);
                            font-weight: 600;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        &:last-child {
                            margin-right: 4.25em;

                            @media screen and (max-width: 1123px) {
                                margin-right: 1.25em;
                            }
                        }

                        .menu-link-icon {
                            color: var(--color-gray-400);
                            font-size: var(--font-size-text3);
                            transition: 0.3s ease-out;
                            margin-left: 0.25em;

                            &:hover {
                                filter: brightness(75%);
                            }
                        }
                    }
                }

                .auth-buttons {
                    display: flex;
                    align-items: center;
                    gap: 1em;

                    @media screen and (max-width: 1123px) {
                        gap: 0.5em;
                    }

                    .auth-button:first-child:not(:last-child) {
                        @media screen and (max-width: 767px) {
                            display: none;
                        }
                    }

                    .active-auth-button {
                        display: none;
                    }
                }
            }
        }

        .action-elements {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: 1.5em;

            @media screen and (max-width: 1123px) {
                margin-left: 0.5em;
            }

            @media screen and (max-width: 567px) {
                width: 100%;
            }

            .search-icon {
                align-self: stretch;

                .search-input-container {
                    @media screen and (max-width: 567px) {
                        left: 0;
                    }
                }
            }

            .language-select {
                margin-left: 1.25em;

                @media screen and (max-width: 1123px) {
                    margin-left: 0.5em;
                }
            }
        }
    }
}
