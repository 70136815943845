.rolesSection {
    .section-title {
        font-weight: 700;
        font-size: var(--font-size-heading1);
        color: var(--color-gray-900);
        text-align: center;
        line-height: 1.25;
        letter-spacing: -0.96px;
    }

    .section-desc {
        font-weight: 500;
        font-size: var(--font-size-text1);
        color: var(--color-gray-700);
        margin: 1em 0 3em;
        text-align: center;
        line-height: 1.6;
    }

    .role-list {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            gap: 2em;
            justify-content: center;
        }

        .role-card {
            max-width: 15em;

            @media screen and (max-width: 1024px) {
                max-width: unset;
                width: 45%;
            }

            .card-header {
                display: flex;
                align-items: center;
                gap: 1.125em;

                .title {
                    font-weight: 600;
                    font-size: var(--font-size-heading3);
                    color: var(--color-gray-900);
                }

                .role-icon {
                    @media screen and (max-width: 480px) {
                        height: 40px;
                        width: 40px;
                        object-fit: contain;
                    }
                }
            }

            .grid-list {
                margin-top: 2em;

                .grid-list-item {
                    display: flex;
                    gap: 0.75em;
                    margin-bottom: 1em;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                .list-item-icon {
                    margin-top: 7px;
                    height: 1em;
                    width: 1em;

                    @media screen and (max-width: 480px) {
                        height: 10px;
                    }
                }

                .list-item-content {
                    font-size: var(--font-size-text2);
                    line-height: 1.556;
                    font-weight: 400;
                    color: var(--color-gray-600);
                    width: 100%;
                }
            }
        }
    }
}
