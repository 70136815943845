.container {
    .header {
        padding: 4rem 1.25rem 7.75rem;
        display: flex;
        gap: 2.5rem;
        background: no-repeat center/100% url('~assets/shapes/organization_background.svg');
        background-size: cover;
        background-color: var(--color-map-800);

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }

        .logo-container {
            width: 11rem;
            height: 11rem;
            min-width: 11rem;
            min-height: 11rem;
            border-radius: 5.5rem;
            background-color: var(--color-white);
            box-shadow: var(-box-shadow-md);
            display: flex;
            align-items: center;
            justify-content: center;

            .logo {
                width: 7.5rem;
                height: 7.5rem;
                object-fit: contain;
            }
        }

        .organization-meta {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;

            .title {
                font-weight: 700;
                font-size: var(--font-size-heading2);
                line-height: 1.22em;
                letter-spacing: -0.02em;
                color: var(--color-white);
                margin-bottom: 1rem;
                width: 80%;

                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }

            .description {
                color: var(--color-primary-100);
                font-weight: 500;
                font-size: var(--font-size-text3);
                line-height: 1.5em;
                width: 80%;

                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }

    .content {
        padding: 1.25rem;
        margin-top: -5.25rem;

        .section {
            background-color: var(--color-white);
            border-radius: 8px;
            box-shadow: var(--box-shadow-xs);
            margin-bottom: 3rem;
            padding: 1rem;

            .map-tabs {
                margin-bottom: 1rem;
            }

            .map-container {
                background-color: var(--color-gray-100);

                .map-legend {
                    left: unset;
                    right: 1.25rem;
                    bottom: 1.25rem;
                    box-shadow: var(--box-shadow-lg);
                    border-radius: 8px;
                }
            }
        }

        .stats-list {
            display: grid;
            gap: 0.5rem;
            grid-template-columns: repeat(4, minmax(max-content, 1fr));

            @media screen and (max-width: 1299px) {
                grid-template-columns: repeat(2, minmax(max-content, 1fr));
            }

            @media screen and (max-width: 767px) {
                grid-template-columns: auto;
            }
        }

        .section-title {
            font-weight: 700;
            font-size: var(--font-size-heading4);
            line-height: 1.17em;
            letter-spacing: -0.02em;
            color: var(--color-gray-900);
            margin-bottom: 1.25rem;
        }

        .table-container {
            overflow: auto;
            border: 1px solid var(--color-gray-300);
            border-radius: 8px;
            box-shadow: var(--box-shadow-xs);
        }

        .button-container {
            display: flex;
            justify-content: center;
            margin: 1.75rem 0;
        }
    }
}
