.container {
    .textarea {
        padding: 0.625em 1em;
        font-size: var(--font-size-text3);
        line-height: 1.5em;
        color: var(--color-gray-900);
        width: 100%;
        border: 1px solid var(--color-border);
        background-color: inherit;
        border-radius: var(--border-radius-small);

        &::placeholder {
            color: var(--color-gray-400);
        }

        &:-ms-input-placeholder {
            color: var(--color-gray-400);
        }

        &::-ms-input-placeholder {
            color: var(--color-gray-400);
        }

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            border: 1px solid var(--color-input-outline);
            outline-color: var(--color-input-outline);
        }
    }

    .textarea-warning {
        border-color: var(--color-warning);
    }

    .warning-text {
        font-size: var(--font-size-text5);
        font-weight: 600;
        color: var(--color-warning);
    }
}
