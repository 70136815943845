.content-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;

    .map-container {
        width: 100%;
        height: 100%;

        .map-legend {
            left: unset;
            right: 1.25rem;
            box-shadow: var(--box-shadow-lg);
        }
    }

    .control-icon-container {
        cursor: pointer;
        color: var(--color-gray-500);
        position: absolute;
        top: 1.5rem;
        left: 19.25rem;
        background-color: var(--color-white);
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        z-index: 10;
        box-shadow: var(--box-shadow-sm);
        transition: filter 0.2s ease-out, 0.3s ease;

        &:hover {
            filter: brightness(95%);
        }

        @media screen and (max-width: 480px) {
            z-index: 7;
        }

        &-collapsed {
            left: -0.75rem;
        }
    }

    .projects-bar {
        position: relative;
        height: 100%;
        overflow-y: auto;
        background-color: var(--color-white);
        border-right: 1px solid var(--color-gray-200);
        padding: 1.25rem;
        min-width: 20rem;
        max-width: 20rem;
        transition: 0.3s ease;
        z-index: 4;

        &-collapsed {
            border-right: 0;
            min-width: 0;
            width: 0;
            padding: 0;
        }
    }
}
