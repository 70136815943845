.label-text {
    font-size: var(--font-size-text5);
    font-weight: 700;
}

.legend-text {
    font-size: var(--font-size-text5);
    font-weight: 500;
    color: var(--color-gray-600);
    margin-left: 0.5em;
    max-width: 6.5rem;
    word-wrap: break-word;
}

:global {
    .recharts-legend-item {
        margin-bottom: 0.5rem;
    }
}
