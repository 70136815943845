.container {
    margin-bottom: 2em;

    .list {
        display: flex;
        flex-flow: wrap;
        gap: 0.375em;
        max-width: fit-content;
        margin-bottom: 0.75em;

        .filter-item {
            display: flex;
            align-items: center;
            background-color: var(--color-primary-600);
            border-radius: 28px;
            padding: 0.25rem 0.5rem;
            color: var(--color-white);

            &-text {
                font-size: 0.6875rem;
                font-weight: 600;
                line-height: 1rem;
            }

            &-icon {
                font-size: 1rem;
                margin-left: 0.175em;
                cursor: pointer;
                transition: 0.3s ease-out;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .clear-link {
        cursor: pointer;
        color: var(--color-primary-600);
        font-size: var(--font-size-text5);
        line-height: 1.5em;
        font-weight: 600;
        transition: 0.2s ease-out;
        max-width: fit-content;

        &:hover {
            filter: brightness(115%);
        }
    }
}
