.modal {
    border-radius: 8px;
    width: 41rem;
    max-width: 90vw;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-xl);
    padding: 1.25rem;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .close-icon {
            color: var(--color-gray-400);
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
                color: var(--color-gray-500);
            }
        }

        .title {
            font-weight: 700;
            font-size: var(--font-size-text1);
            line-height: 1.5em;
            color: var(--color-gray-900);
        }
    }

    .description {
        margin-bottom: 1.25rem;
        font-size: var(--font-size-text3);
        font-weight: 500;
        line-height: 1.5em;
        color: var(--color-gray-600);
    }

    .options {
        display: flex;
        gap: 0.75rem;

        .option-item {
            border-radius: 4px;
            border: 1px solid var(--color-gray-300);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            cursor: pointer;
            flex: 1;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: var(--color-gray-50);
                border-color: var(--color-primary-400);
            }

            .option-icon {
                color: var(--color-primary-600);
            }

            .option-title {
                font-size: var(--font-size-text3);
                font-weight: 700;
                line-height: 1.5em;
                color: var(--color-gray-800);
            }

            .option-description {
                color: var(--color-gray-700);
                font-size: var(--font-size-text4);
                line-height: 1.57em;
            }
        }
    }
}
