.stats-list {
    margin: -2.5em 0 2em;
    background: var(--color-white);
    border-radius: 12px;
    box-shadow: var(--box-shadow-lg);
    padding: 1em 0.75em;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(4, minmax(max-content, 1fr));

    @media screen and (max-width: 1139px) {
        grid-template-columns: repeat(2, minmax(max-content, 1fr));
    }

    @media screen and (max-width: 591px) {
        grid-template-columns: auto;
    }
}
