.modal {
    top: unset;
    transform: unset;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 95vh;
    align-self: flex-end;
    border-radius: 16px 16px 0 0;
    background-color: var(--color-gray-50);
    overflow-y: auto;
    animation: slide-up 0.2s ease-out forwards;

    .header {
        background-color: var(--color-white);
        min-height: 7rem;
        max-height: 7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        z-index: 1;
        top: 0;
        border-bottom: 1px solid var(--color-gray-200);

        .header-content {
            display: flex;
            justify-content: space-between;

            .header-title {
                .title {
                    font-size: var(--font-size-heading3);
                    color: var(--color-gray-900);
                    letter-spacing: -0.02em;
                    line-height: 1.27em;
                    margin-bottom: 0.5rem;
                }

                .sub-text {
                    display: flex;
                    align-items: center;
                    font-size: var(--font-size-text4);
                    font-weight: 600;
                    color: var(--color-gray-600);

                    .select {
                        color: var(--color-gray-800);
                        font-size: var(--font-size-text4);
                        line-height: 1.57em;
                        font-weight: 500;
                        margin: 0 0.75rem;
                        background-color: var(--color-white);
                        border-color: var(--color-gray-300);
                        border-radius: 4px;

                        &__control {
                            min-width: 50px;
                            height: 2rem;
                            background-color: transparent;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }

            .header-button {
                align-self: flex-start;

                @media screen and (max-width: 527px) {
                    margin-right: 4.25rem;
                }
            }
        }

        .close-icon-container {
            position: absolute;
            right: 1.25rem;
            top: 1.25rem;
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 1.625rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--color-gray-200);
            transition: 0.3s ease;
            cursor: pointer;

            &:hover {
                filter: brightness(95%);
            }

            .close-icon {
                color: var(--color-gray-500);
            }
        }
    }

    .content-container {
        .tab-header-container {
            display: flex;
            justify-content: center;
            flex: 1;
            background-color: var(--color-white);
            position: sticky;
            top: 7rem;
            z-index: 1;
            box-shadow: var(--box-shadow-xs);

            .tab-header-item {
                padding: 0.75rem 1.5rem;
                font-size: var(--font-size-text2);
                font-weight: 700;
                color: var(--color-gray-800);

                &-active {
                    border-bottom-width: 3px;
                    color: var(--color-primary-600);
                }

                &:not(:first-child) {
                    margin-left: 0;
                }
            }
        }

        .tab-content-container {
            display: flex;
            justify-content: center;
            padding: 1.5rem 0;
        }
    }
}

.form-container {
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-sm);
    border-radius: 8px;
    padding: 1.25rem 2rem 2.5rem;
}

.content-layout-wrapper {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
}

.content-layout {
    width: 100%;
    max-width: 50vw;

    @media screen and (max-width: 1199px) {
        max-width: 60vw;
    }

    @media screen and (max-width: 1023px) {
        max-width: 72vw;
    }

    @media screen and (max-width: 767px) {
        max-width: 80vw;
    }

    @media screen and (max-width: 527px) {
        max-width: 95vw;
    }
}

@keyframes slide-up {
    from {
        transform: translateY(75%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
