.container {
    width: 100%;
    height: 100%;
    position: relative;

    .bubble-legend {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 35%;

        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 0.75rem;

            .legend-indicator {
                width: 14px;
                height: 14px;
                border-radius: 50%;
            }

            .legend-text {
                font-size: var(--font-size-text5);
                font-weight: 500;
                color: var(--color-gray-600);
                margin-left: 0.5em;
            }
        }

        &-vertical {
            top: unset;
            bottom: 0;
            transform: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
            max-height: 150px;
        }
    }
}
