.auth-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25em;

    .input-label {
        font-size: var(--font-size-text4);
        font-weight: 500;
        line-height: 1.57;
        text-align: left;
        color: var(--color-gray-900);
        margin-bottom: 0.25em;
    }

    .input {
        width: 100%;
        box-shadow: 0 1px 2px 0 #1018280d;
    }
}
