.container {
    padding: 1.5rem 1.25rem;

    .section {
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: var(--box-shadow-xs);
        margin-bottom: 3rem;
        padding: 1rem;

        .map-tabs {
            margin-bottom: 1rem;
        }

        .map-container {
            background-color: var(--color-gray-100);

            .map-legend {
                left: unset;
                right: 1.25rem;
                bottom: 1.25rem;
                box-shadow: var(--box-shadow-lg);
                border-radius: 8px;
            }
        }
    }

    .stats-list {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: repeat(4, minmax(max-content, 1fr));

        @media screen and (max-width: 1299px) {
            grid-template-columns: repeat(2, minmax(max-content, 1fr));
        }

        @media screen and (max-width: 767px) {
            grid-template-columns: auto;
        }
    }

    .section-title {
        font-weight: 700;
        font-size: var(--font-size-heading4);
        line-height: 1.17em;
        letter-spacing: -0.02em;
        color: var(--color-gray-900);
        margin-bottom: 1.25rem;
    }

    .table-container {
        overflow: auto;
        border: 1px solid var(--color-gray-300);
        border-radius: 8px;
        box-shadow: var(--box-shadow-xs);
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin: 1.75rem 0;
    }
}

.form-notice-container {
    border: 1px solid var(--color-primary-200);
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    align-items: center;
    background-color: var(--color-primary-50);
    margin-bottom: 1rem;

    .notice-icon-container {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 0.875rem;
        background-color: var(--color-primary-200);
        display: flex;
        align-items: center;
        justify-content: center;

        .notice-icon {
            color: var(--color-primary-600);
            font-size: var(--font-size-text3);
        }
    }

    .notice-description {
        flex: 1;
        margin-left: 0.75rem;
        padding-right: 5%;
        color: var(--color-gray-800);
        font-weight: 500;
        font-size: var(--font-size-text3);
        line-height: 1.5rem;

        .notice-by {
            text-transform: capitalize;
        }

        .notice-project {
            font-weight: 700;
        }
    }
}
