.container {
    display: flex;
    flex-direction: column;
    background-color: var(--color-gray-50);
    padding: 4em 0 0;

    .header {
        .title {
            font-weight: 700;
            font-size: var(--font-size-heading1);
            line-height: 1.25em;
            text-align: center;
            margin-bottom: 1rem;
        }

        .description {
            font-size: var(--font-size-text2);
            line-height: 1.56em;
            text-align: center;
            margin-bottom: 3rem;
        }
    }

    .map-tabs {
        align-self: center;
        margin-bottom: 2rem;
        max-width: 90%;
    }

    .map-section {
        .map-header {
            display: flex;
            background-color: var(--color-white);
            padding: 1.5em 2em;
            gap: 0.75em;
            flex-wrap: wrap;
            justify-content: space-between;

            @media screen and (max-width: 480px) {
                flex-direction: column;
                align-items: center;
            }

            .buttons {
                display: flex;
                align-items: center;
                gap: 0.75em;
                position: relative;

                .message {
                    display: none; // todo show if required
                    position: absolute;
                    top: -1.5em;
                    left: 0;
                    color: var(--color-gray-600);
                    font-size: var(--font-size-text4);
                    font-weight: 500;
                }
            }
        }
    }
}
