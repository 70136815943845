.data-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0.75rem;
    background-color: var(--color-primary-50);
    border-radius: 4px;

    &-key {
        color: var(--color-gray-600);
        font-size: var(--font-size-text4);
        font-weight: 500;
        line-height: 1.57em;
    }

    &-value {
        font-weight: 700;
        font-size: var(--font-size-text1);
        line-height: 1.6em;
        color: var(--color-gray-800);
    }
}
