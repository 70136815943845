.modal {
    border-radius: 8px;
    width: 32rem;
    max-width: 90vw;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-xl);
    padding: 1.25rem;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .close-icon {
            color: var(--color-gray-400);
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
                color: var(--color-gray-500);
            }
        }

        .title {
            font-weight: 700;
            font-size: var(--font-size-text1);
            line-height: 1.5em;
            color: var(--color-gray-900);
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        .input-label {
            font-size: var(--font-size-text4);
            color: var(--color-gray-600);
            font-weight: 500;
        }

        .buttons {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 1rem;
            margin-top: 1rem;

            .button {
                flex: 1;
            }
        }
    }
}
