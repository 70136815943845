.container {
    margin-bottom: 1.25rem;
    display: flex;
    align-items: flex-start;
    flex-flow: wrap;
    gap: 1rem;

    .input-container {
        flex: 1;

        .select {
            display: flex;
            flex-direction: column;
            align-self: center;
            width: 100%;
            min-width: max-content;
            color: var(--color-gray-900);
            font-size: var(--font-size-text3);
            background-color: var(--color-white);
            border-radius: 0.25em;

            &-disabled {
                background-color: var(--color-gray-50);
                filter: unset;
            }

            .options-wrapper {
                background-color: var(--color-white);

                .select-option-style {
                    padding: 0.75em 0.5em;

                    .option-item-style {
                        border-radius: 0.25em;
                        margin-bottom: 0.25em;
                    }
                }
            }

            .select-control {
                background-color: var(--color-neutral-50);
                padding: 0.625rem 1rem;
                cursor: pointer;

                input {
                    font-size: var(--font-size-text3);
                }
            }
        }

        .label {
            font-size: var(--font-size-text4);
            font-weight: 500;
            line-height: 1.57;
            text-align: left;
            color: var(--color-gray-600);
            margin-bottom: 0.25em;
        }
    }
}
