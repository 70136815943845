.auth-container {
    background-color: var(--color-gray-100);
    min-height: 100vh;
    width: 100vw;
    padding: 10vh 0;
    display: flex;
    justify-content: center;

    .auth-box {
        height: fit-content;
        width: 100%;
        max-width: 31.93em;
        border-radius: 0.5em;
        padding: 2.5em;
        box-shadow: 0 1px 2px 0 #1018280d;
        background-color: var(--color-white);

        @media screen and (max-width: 768px) {
            width: 90%;
        }

        .auth-heading {
            font-size: var(--font-size-heading3);
            font-weight: 700;
            line-height: 1.27;
            letter-spacing: -0.0375em;
            text-align: center;
            color: var(--color-gray-900);
        }

        .auth-sub-heading {
            font-size: var(--font-size-text3);
            font-weight: 500;
            line-height: 1.5;
            text-align: center;
            color: var(--color-gray-500);
            margin: 0.75em 0 1.5em;
        }

        .form-wrapper {
            .contact-input {
                display: inline-grid;
                width: 100%;
                margin-bottom: 1.25em;

                .auth-input {
                    width: 100%;
                    display: flex;
                    margin-bottom: 1.25em;

                    .input {
                        box-shadow: 0 1px 2px 0 #1018280d;
                    }
                }
            }

            .file-input {
                width: 100%;
                margin-bottom: 1.25em;
                box-shadow: 0 1px 2px 0 #1018280d;
            }

            .select-input {
                width: 100%;
                margin-bottom: 1.25em;
            }

            .file-preview {
                margin-top: 0.5rem;
                margin-bottom: 1.25rem;
            }

            .input-label {
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57;
                text-align: left;
                color: var(--color-gray-900);
                margin-bottom: 0.25em;
            }

            .msg-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .checkWrapper {
                    display: flex;
                    align-items: center;

                    .message {
                        margin-left: 0.5em;
                        font-size: var(--font-size-text4);
                        font-weight: 500;
                        line-height: 1.57;
                        text-align: center;
                        color: var(--color-gray-700);
                    }
                }
            }

            .auth-button {
                width: 100%;
                margin: 2em 0;
                justify-content: center;
            }

            .error-message,
            .error-message * {
                color: var(--color-error-700);
                text-align: center;
                font-size: var(--font-size-text3);
                font-weight: 500;
                margin-top: 1rem;
            }
        }

        .link-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .register-message {
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57;
                text-align: center;
                color: var(--color-gray-500);
            }

            .register-link {
                font-size: var(--font-size-text4);
                font-weight: 600;
                line-height: 1.57;
                text-align: center;
                color: var(--color-primary-600);
                text-decoration: none;
                margin-left: 0.25em;
                transition: 0.2s ease-out;

                &:hover {
                    filter: brightness(110%);
                }
            }
        }
    }
}
