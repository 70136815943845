.container {
    padding: 1.5rem 1.25rem;
    height: 100%;
    min-height: 90vh;

    .title {
        font-weight: 700;
        font-size: var(--font-size-heading4);
        color: var(--color-gray-900);
        letter-spacing: -0.02em;
        margin-bottom: 1.25rem;
    }

    .organizations {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
        gap: 1rem;

        .organization-item {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            min-height: 200px;
            background-color: var(--color-white);
            box-shadow: var(--box-shaodw-xs);
            padding: 0.5rem;
            border: 1px solid var(--color-white);
            border-radius: 4px;

            .organization-logo {
                width: 120px;
                height: 120px;
                object-fit: contain;
                text-align: center;

                &-placeholder {
                    width: 60px;
                    height: 120px;
                }
            }

            .organization-title {
                text-align: center;
                font-size: var(--font-size-text5);
                font-weight: 500;
                line-height: 1.5em;
                color: var(--color-gray-600);
            }

            &:hover {
                border-color: var(--color-primary-200);
                box-shadow: var(--box-shadow-lg);
            }
        }

        .empty-message {
            font-size: var(--font-size-text3);
            color: var(--color-gray-900);
        }
    }
}
