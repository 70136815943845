.modal {
    background-color: var(--color-white);
    width: 27.75em;
    max-width: 90%;
    box-shadow: var(--box-shadow-xl);
    border-radius: 0.5em;
    padding: 2em;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25em;

        .lock-wrapper {
            height: 2.5em;
            width: 2.5em;
            border-radius: 50%;
            background-color: var(--color-primary-100);
            display: flex;
            justify-content: center;
            align-items: center;

            .lock-icon {
                color: var(--color-primary-600);
                font-size: var(--font-size-heading4);
            }
        }

        .close-icon {
            cursor: pointer;
            color: var(--color-gray-400);
            font-size: var(--font-size-heading4);
        }
    }

    .title {
        font-size: var(--font-size-text1);
        line-height: 2em;
        color: var(--color-gray-900);
    }

    .form-wrapper {
        margin-bottom: 1.25em;

        .error-message {
            color: var(--color-danger);
            font-size: var(--font-size-text4);
            letter-spacing: 0.3px;
            font-weight: 500;
        }

        .input-wrapper {
            margin-top: 1.5em;

            .otp-container {
                display: flex;
                gap: 0.5em;

                .otp-input {
                    height: 56px;
                    width: 56px;
                    border-radius: 0.25em;
                    display: flex;
                    justify-items: center;
                    align-items: center;
                    text-align: center;
                    border: 1px solid var(--color-gray-300);
                    font-size: var(--font-size-heading4);
                    line-height: 1.416;
                    font-weight: 700;
                    color: var(--color-gray-700);

                    &-error {
                        border: 1px solid var(--color-error-500);
                    }

                    &::-webkit-inner-spin-button {
                        appearance: none;
                        margin: 0;
                    }

                    &:focus,
                    &:hover {
                        border: 1px solid var(--color-primary-400);
                    }
                }
            }
        }

        .input-label {
            font-size: var(--font-size-text4);
            color: var(--color-gray-600);
            font-weight: 500;
        }

        .button {
            margin-top: 1.5em;
            width: 100%;
        }
    }

    .resend-button {
        display: flex;
        align-items: center;
        gap: 0.5em;

        .message {
            font-size: var(--font-size-text4);
            line-height: 1.57;
            font-weight: 500;
            color: var(--color-gray-500);
        }

        .link {
            cursor: pointer;
            font-size: var(--font-size-text4);
            line-height: 1.57;
            font-weight: 600;
            color: var(--color-primary-600);
        }
    }
}
