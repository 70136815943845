.container {
    margin: 0 auto;
    padding: 2.25rem 0;
    width: 70%;

    @media screen and (max-width: 992px) {
        width: 80%;
    }

    @media screen and (max-width: 480px) {
        width: 90%;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .title {
            font-size: var(--font-size-heading4);
            line-height: 1.17em;
            font-weight: 700;
            color: var(--color-gray-900);
            letter-spacing: -0.02em;
        }
    }

    .content {
        background-color: var(--color-white);
        border-radius: 4px;
        filter: drop-shadow(0 1px 2px rgb(16 24 40 / 5%));

        &-header {
            padding: 0.75rem 1.5rem;
            border-bottom: 1px solid var(--color-gray-300);
        }

        &-body {
            padding: 0.75rem 1.5rem;

            &-item {
                display: flex;
                align-items: flex-start;
                gap: 0.5rem;
                padding: 1rem 0;

                &:not(:last-child) {
                    border-bottom: 1px solid var(--color-gray-200);
                }

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                }

                .label {
                    font-size: var(--font-size-text4);
                    font-weight: 500;
                    line-height: 1.57em;
                    color: var(--color-gray-500);
                    flex: 0 0 30%;
                }

                .value {
                    flex: 1;
                    font-size: var(--font-size-text3);
                    color: var(--color-gray-900);
                    line-height: 1.5em;
                    font-weight: 500;

                    &-description {
                        font-weight: 400;
                    }

                    .logo {
                        height: 80px;
                    }
                }
            }
        }
    }

    .footer {
        margin: 1.5rem 0 4rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.75rem;
    }
}

.description-text {
    font-size: var(--font-size-text3);
    line-height: 1.5em;
    color: var(--color-gray-600);
    margin-bottom: 0.5rem;

    .description-email {
        font-weight: 600;
        color: var(--color-gray-800);
    }
}

.decline-description {
    margin-bottom: 0.5rem;
}
