.table {
  border: 1px solid var(--color-border);
  border-collapse: collapse;
  width: 100%;
  font-size: var(--font-size-normal);

  .head {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  .row {
    border: 1px solid var(--color-border);

    &:hover {
      background-color: #f0f0f0bf;
    }
  }

  .data {
    border: 1px solid var(--color-border);
    padding: 0.5rem 0.75rem;
  }
}
