.container {
    position: relative;

    &:hover {
        background-color: var(--color-gray-50);
    }

    .left-icon {
        margin-left: 0.57rem;
        color: var(--color-gray-500);
    }

    .select {
        flex: 1;

        .control-button {
            box-shadow: var(--box-shadow-xs);
            padding: 0.45em 0.75em;

            &-icon {
                font-size: var(--font-size-heading4);
            }
        }
    }
}

.options-wrapper {
    width: fit-content;
    min-width: 25rem;

    div {
        font-size: var(--font-size-text4);
    }

    .select-option {
        padding-right: 1rem;
        margin: 2px 0;
        font-size: var(--font-size-text4);
        gap: unset;

        & > :first-child {
            width: 2rem;
        }

        & > :last-child {
            max-width: calc(100% - 2rem);
        }
    }
}
