.tab-header {
    border-bottom: 0;
    background-color: var(--color-white);
    overflow-x: auto;

    .tab-header-item {
        transition: background-color 0.2s ease-out;
        min-width: fit-content;
        user-select: none;

        &-active {
            color: var(--color-primary-600);
        }
    }
}

.tab-header-primary {
    padding: 4px;
    border-radius: 60px;
    box-shadow: var(--box-shadow-sm);
    font-size: var(--font-size-text3);

    .tab-header-item-primary {
        padding: 0.75em 2em;
        border-radius: 60px;
        font-weight: 700;

        &:not(:first-child, :last-child) {
            margin: 0 4px;
        }

        &:hover:not(.tab-header-item-primary-active) {
            background-color: var(--color-gray-50);
        }

        &-active {
            background-color: var(--color-primary-50);
        }
    }
}

.tab-header-secondary {
    border-radius: 4px;

    .tab-header-item-secondary {
        padding: 0.5em 1.5em;
        font-weight: 600;
        border-radius: 0;
        border: 1px solid var(--color-gray-300);
        font-size: var(--font-size-text3);

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
            border-right-width: 1px;
        }

        &:not(:first-child, .tab-header-item-secondary-active) {
            border-left: 0;
        }

        &:hover:not(.tab-header-item-secondary-active) {
            background-color: var(--color-gray-50);
        }

        &-active {
            background-color: var(--color-primary-50);
            border: 1px solid var(--color-primary-600);
        }
    }
}

.tab-header-tertiary {
    .tab-header-item-tertiary {
        color: var(--color-gray-400);
        padding-bottom: 0.4em;
        font-weight: 700;
        transition: filter 0.2s ease;
        font-size: var(--font-size-text4);
        line-height: 1.57em;

        &:not(:first-child) {
            margin-left: 1em;
        }

        &:hover:not(.tab-header-item-tertiary-active) {
            filter: brightness(90%);
        }

        &-active {
            color: var(--color-primary-600);
            border-bottom: 2px solid var(--color-primary-600);
        }
    }
}
