.dropzone {
    width: 100%;
    border: 1px solid var(--color-border);
    border-radius: 4px;

    &:hover {
        border: 1px solid var(--color-input-outline);
    }

    &-active {
        border: 2px solid var(--color-input-outline);
    }

    .dropzone-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .upload-icon-container {
            background-color: var(--color-gray-50);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.25em 0;

            .upload-icon {
                padding: 0.375em;
                color: var(--color-gray-600);
            }
        }

        .upload-title {
            color: var(--color-gray-500);
            font-weight: 500;
            font-size: var(--font-size-text4);
            line-height: 1.5em;
            text-align: center;
            margin: 0.25em 0;

            .link {
                color: var(--color-primary-600);
                font-weight: 500;
                font-size: var(--font-size-text4);
                line-height: 1.5em;
            }
        }

        .upload-text {
            color: var(--color-gray-500);
            font-size: var(--font-size-text5);
            line-height: 1.5em;
            text-align: center;
            margin: 0.25em 0;
        }
    }
}

.file-preview-container {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    width: 100%;
    background-color: var(--color-primary-50);
    border: 1px solid var(--color-primary-200);
    border-radius: 4px;
    margin-bottom: 0.75rem;

    .file-icon-container {
        background-color: var(--color-primary-200);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;

        .file-icon {
            color: var(--color-primary-600);
            font-size: var(--font-size-text2);
        }
    }

    .content {
        flex: 1;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .file-name {
                color: var(--color-gray-600);
                font-size: var(--font-size-text4);
                font-weight: 600;
                line-height: 1.57em;
            }

            .remove-icon {
                color: var(--color-gray-400);
                cursor: pointer;
                transition: 0.2s ease-out;

                &:hover {
                    color: var(--color-gray-500);
                }
            }

            .preview-icon {
                color: var(--color-primary-600);
                cursor: pointer;
                font-size: var(--font-size-text1);
            }
        }

        .size {
            color: var(--color-gray-500);
            font-size: var(--font-size-text4);
            line-height: 1.57rem;
        }
    }
}
