@import './mixin';
@import './reset';

:root {
  --color-white: white;
  --color-black: black;

  --color-background: white;
  --color-background-alt: #f8f8f8;

  --color-text: #505050;
  --color-text-light: #8c8c8c;

  --color-border: #dadada;
  --color-input-outline: #b6def7;
  --color-cards: #f8f8f8;

  --border-radius-small: 4px;

  --color-primary: #1EA7FD;
  --color-primary-text: white;

  --color-success: #5cb85c;
  --color-success-text: white;
  --color-warning: #eea236;
  --color-warning-text: white;
  --color-danger: #d9534f;
  --color-danger-text: white;

  --color-list-highlight: #ececec;
  --color-list-highlight-alt: #e0e0e0;

  --font-size-small: 12px;
  --font-size-normal: 14px;

  --font-size-input: 14px;
}
