.header {
    background-image: url('/assets/images/hero-image.webp');
    background-size: cover;
    width: 100%;

    .overlay-bg {
        padding: 8.75em 0 10.375em;
        background: linear-gradient(270deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 81%) 100%);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 567px) {
            padding: 7em 0;
            background: linear-gradient(200deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 81%) 100%);
        }

        .loadingWrapper {
            padding: 8.75em 0 10.375em;
            display: flex;
            justify-content: center;
        }

        .hero-info {
            display: flex;
            flex-direction: column;

            .hero-title {
                max-width: 11em;
                color: var(--color-white);
                font-size: var(--font-size-display2);
                line-height: 1.2;
                font-weight: 700;
                letter-spacing: -1.2px;
            }

            .hero-desc {
                margin: 2em 0 2.5em;
                max-width: 35em;
                font-size: var(--font-size-text2);
                color: var(--color-gray-50);
                font-weight: 400;
                line-height: 1.56;
            }

            .explore-link {
                width: fit-content;

                .explore-button {
                    width: 10em;
                }
            }
        }
    }
}
