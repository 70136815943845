.dropZone {
    border: 2px dashed var(--color-border);
    padding: 0.5rem;
    cursor: pointer;
    display: inline-block;

    &Disabled {
        pointer-events: none;
    }

    &Warning:not(:hover) {
        border-color: var(--color-warning);
    }

    &Error:not(:hover) {
        border-color: var(--color-error);
    }

    &:hover,
    &:focus {
        border: 2px dashed var(--color-input-outline);
        outline-color: var(--color-input-outline);
    }

    .fileInput {
        display: none;
    }
}
