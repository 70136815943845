.organization-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em 6.5em 5em;

    @media screen and (max-width: 1024px) {
        padding: 5em 0;
    }

    .section-title {
        font-weight: 700;
        font-size: var(--font-size-heading4);
        color: var(--color-gray-800);
        text-transform: uppercase;
        text-align: center;
        line-height: 1.16;
        letter-spacing: 1.92px;
        margin-bottom: 0.5em;
    }

    .organization-list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .logo-wrapper {
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s;
            margin-right: 10em;

            @media screen and (max-width: 480px) {
                margin-right: 6em;
                height: 45px;
            }

            .logo {
                height: 100%;
                width: 100%;
                max-height: 12.5rem;
                max-width: 10rem;
                object-fit: contain;
            }

            &:hover {
                transform: scale(0.95);
            }
        }
    }

    .register {
        margin-top: 3.25em;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.5em;
        gap: 7em;
        border: 1px solid var(--color-gray-300);
        border-radius: 0.25em;

        @media screen and (max-width: 768px) {
            gap: 1em;
            max-width: 90%;
        }

        .register-text {
            font-weight: 600;
            color: var(--color-gray-800);
            font-size: var(--font-size-text2);
            line-height: 1.556;
        }

        .register-link {
            font-weight: 400;
            width: fit-content;
            font-size: var(--font-size-heading4);
        }
    }
}
