.list {
    display: flex;
    align-items: center;
    flex-flow: wrap;

    .list-item {
        display: flex;
        align-items: center;
        gap: 2rem;

        .arrow {
            align-self: flex-start;
            margin-top: 1rem;
            font-size: 3.375rem;
            color: var(--color-gray-500);
        }

        .organization-item {
            margin-right: 4.5rem;
            cursor: pointer;
            max-width: fit-content;

            .organization-logo {
                height: 5.5rem;
                max-width: 7.5rem;
                object-fit: contain;
                margin-bottom: 1.25rem;
            }

            .organization-info {
                display: flex;

                .organization-title {
                    color: var(--color-gray-900);
                    font-size: var(--font-size-text3);
                    font-weight: 700;
                    line-height: 1.5em;
                }

                .link-icon-container {
                    margin-left: 0.75rem;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-color: var(--color-primary-100);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .link-icon {
                        font-size: var(--font-size-text1);
                        color: var(--color-primary-600);
                    }
                }
            }

            &:hover {
                .organization-info .organization-title {
                    text-decoration: underline;
                }
            }
        }
    }
}
