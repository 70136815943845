.search-bar {
    display: flex;
    align-items: center;
    position: relative;

    .search-icon {
        color: var(--color-gray-700);
        cursor: pointer;
        transition: 0.3s ease-out;

        &:hover {
            color: var(--color-gray-500);
        }
    }
}

.modal {
    top: 20%;
    transform: translate(-50%, 0);
    border-radius: 8px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-xl);
    width: 40rem;
    max-width: 90vw;
    max-height: 60vh;

    .modal-content {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        width: 100%;

        .search-input-container {
            width: 100%;

            .search-input {
                width: 100%;
                background-color: var(--color-gray-50);
                border: 1px solid var(--color-gray-300);
            }
        }

        .search-result {
            margin-top: 1.5rem;
            flex: 1;

            .empty-text {
                text-align: center;
                color: var(--color-gray-600);
                font-weight: 600;
                font-size: var(--font-size-text4);
                line-height: 1.57em;
            }

            .no-results {
                padding: 3rem 0 4.5rem;

                &-title {
                    font-weight: 700;
                    font-size: var(--font-size-heading4);
                    line-height: 1.17em;
                    letter-spacing: -0.02em;
                    color: var(--color-gray-900);
                    text-align: center;
                    margin-bottom: 0.75rem;
                }

                &-text {
                    color: var(--color-gray-600);
                    font-size: var(--font-size-text4);
                    line-height: 1.57em;
                    text-align: center;
                }
            }

            .results {
                display: flex;
                flex-direction: column;
                max-height: 40vh;
                overflow-y: auto;

                .project-item-container {
                    padding: 0.75rem 0;

                    .project-item {
                        display: flex;
                        border-radius: 8px;
                        padding: 0.75rem;
                        transition: 0.2s ease-out;
                        cursor: pointer;

                        .logo-container {
                            border-radius: 4px;
                            width: 3.25rem;
                            height: 3.25rem;
                            min-width: 3.25rem;
                            min-height: 3.25rem;
                            overflow: hidden;
                            background-color: var(--color-white);
                            margin-right: 0.75rem;

                            .logo {
                                width: 3.25rem;
                                height: 3.25rem;
                                object-fit: contain;
                            }

                            .logo-placeholder {
                                font-size: 1.5rem;
                            }
                        }

                        .details {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;

                            .project-title {
                                font-size: var(--font-size-text4);
                                font-weight: 700;
                                line-height: 1.57em;
                                color: var(--color-gray-800);
                            }

                            .project-info {
                                display: flex;
                                align-items: center;

                                .organization-name {
                                    font-size: var(--font-size-text5);
                                    color: var(--color-gray-600);
                                    font-weight: 500;
                                    line-height: 1.5em;
                                }

                                .status {
                                    border-radius: 32px;
                                    background-color: var(--color-gray-100);
                                    padding: 0.25rem 0.75rem;
                                    display: flex;
                                    align-items: center;
                                    margin-left: 0.25rem;

                                    &-indicator {
                                        width: 0.75rem;
                                        height: 0.75rem;
                                        border-radius: 50%;
                                        background-color: var(--color-primary-200);
                                        margin-right: 0.375rem;

                                        &-active {
                                            background-color: var(--color-warning-500);
                                        }

                                        &-completed {
                                            background-color: var(--color-success-500);
                                        }

                                        &-dropped {
                                            background-color: var(--color-error-500);
                                        }
                                    }

                                    &-text {
                                        color: var(--color-gray-600);
                                        font-size: var(--font-size-text5);
                                        font-weight: 500;
                                        line-height: 1.5em;
                                    }
                                }
                            }
                        }

                        &:hover {
                            background-color: var(--color-primary-50);

                            .details .project-info .status {
                                background-color: var(--color-white);
                            }
                        }
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--color-gray-200);
                    }

                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
