.notification-icon-container {
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
        filter: brightness(95%);
    }
}

.notifications-popup {
    box-shadow: var(--box-shadow-lg);
    border-radius: 4px;
    margin-top: 1rem;
    background-color: var(--color-white);

    .notifications-dropdown {
        border-radius: 4px;
        padding: 1rem 1rem 0;
        width: 22.75rem;
        max-width: 70vw;
        border-bottom: 1px solid var(--color-gray-200);
        max-height: 60vh;
        overflow-y: auto;
    }

    .see-all-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.625rem 0;
        color: var(--color-primary-600);
        font-size: var(-font-size-text3);
        font-weight: 600;
        line-height: 1.5em;
        transition: 0.2s ease-out;

        &:hover {
            filter: brightness(115%);
        }
    }
}

.notification-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.75rem 0 1rem;
    position: relative;

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-gray-200);
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 2.5rem;
        height: 2.5rem;
        background-color: var(--color-warning-500);
        border-radius: 50%;

        .notification-icon {
            font-size: var(--font-size-heading4);
            color: var(--color-white);
        }

        &-account {
            background-color: var(--color-primary-400);
        }
    }

    .notification-content {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .notification-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;

            .description {
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57em;
                color: var(--color-gray-800);
                max-width: 90%;
                margin-bottom: 0.5rem;
            }
        }

        .notification-date {
            font-size: var(--font-size-text5);
            line-height: 1.5em;
            color: var(--color-gray-400);
            min-width: max-content;
        }
    }

    .new-indicator {
        position: absolute;
        bottom: 1rem;
        right: 0;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: var(--color-primary-600);
    }
}
