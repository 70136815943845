.option {
  padding: 0.5em 0.8em;

  &.focused {
    background-color: var(--color-list-highlight);
  }

  &.selected {
    background-color: var(--color-primary);
    color: var(--color-primary-text);
  }
}
