.container {
    display: flex;
    flex-direction: column;
    width: 7.5em;

    .title {
        display: flex;
        align-items: center;

        .indicator {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            margin-right: 0.5em;
            background-color: var(--color-primary);

            &-active {
                background-color: var(--color-warning-500);
            }

            &-completed {
                background-color: var(--color-success-500);
            }

            &-dropped {
                background-color: var(--color-error-500);
            }
        }

        .title-text {
            font-weight: 500;
            color: var(--color-gray-800);
            font-size: var(--font-size-text4);
            line-height: 157%;
        }
    }

    .content {
        display: flex;
        align-items: center;

        .progress-bar {
            flex: 0 0 80%;
            height: 4px;
            background-color: var(--color-gray-200);
            border-radius: 4px;

            &-pipeline {
                background-color: var(--color-primary-100);
            }

            .active-track {
                background-color: var(--color-primary);
                height: 100%;
                border-radius: 4px;

                &-active {
                    background-color: var(--color-warning-500);
                }

                &-completed {
                    background-color: var(--color-success-500);
                }

                &-dropped {
                    background-color: var(--color-error-500);
                }
            }
        }

        .percentage {
            margin-left: 3px;
            flex: 0 0 20%;
            font-size: 10px;
            color: var(--color-gray-500);
            font-weight: 500;
            line-height: 120%;

            .complete-icon {
                font-size: var(--font-size-text5);
                color: var(--color-primary-600);
            }
        }
    }
}
