.container {
    width: 100%;

    .content {
        margin: 0 auto;
        padding: 1.25rem 0;
        width: 50%;

        @media screen and (max-width: 992px) {
            width: 60%;
        }

        @media screen and (max-width: 768px) {
            width: 75%;
        }

        @media screen and (max-width: 480px) {
            width: 90%;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1.25rem;

            .title {
                font-size: var(--font-size-heading4);
                line-height: 1.17em;
                font-weight: 700;
                color: var(--color-gray-900);
                letter-spacing: -0.02em;
            }

            .mark-read-link {
                color: var(--color-primary-600);
                font-size: var(--font-size-text4);
                font-weight: 600;
                cursor: pointer;
                transition: 0.2s ease-out;

                &:hover {
                    filter: brightness(115%);
                }
            }
        }

        .notifications {
            background-color: var(--color-white);
            padding: 0.75rem 2rem;
            overflow-y: auto;
        }
    }
}
