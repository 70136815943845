.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .content {
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon-container {
            background-color: var(--color-gray-200);
            border-radius: 4px;
            padding: 0.75rem;
            display: flex;
            align-items: center;
        }

        .details {
            align-self: flex-start;
            margin-top: 0.25rem;

            .title {
                font-size: var(--font-size-text4);
                font-weight: 600;
                line-height: 1.57em;
                color: var(--color-gray-600);
            }
        }
    }

    .footer {
        margin-top: 1.25rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
    }
}
