.container {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-right: auto;
    margin-left: auto;
    transition: 0.5s ease-out;

    &-jumbotron {
        padding: 5em 1.25em;

        @media screen and (max-width: 767px) {
            padding: 5em 1.25em;
        }
    }

    @media screen and (min-width: 992px) {
        max-width: 96vw;
    }

    @media screen and (min-width: 1200px) {
        max-width: 1140px;
    }

    @media screen and (min-width: 1300px) {
        max-width: 1240px;
    }
}
