.modal {
    border-radius: 8px;
    width: 28em;
    max-width: 90vw;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-xl);
    padding: 1.25rem;

    .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5em;

        .header-controls {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 0.75em;

            .close-icon {
                color: var(--color-gray-400);
                cursor: pointer;
                transition: 0.3s ease;

                &:hover {
                    color: var(--color-gray-500);
                }
            }
        }

        .title {
            font-weight: 700;
            font-size: var(--font-size-text1);
            line-height: 1.5em;
            color: var(--color-gray-900);
        }
    }

    .description {
        margin-bottom: 0.5em;
        font-size: var(--font-size-text3);
        line-height: 1.5em;
        color: var(--color-gray-600);
    }

    .buttons {
        display: flex;
        width: 100%;
        margin-top: 0.75em;

        .button {
            flex: 1;
            justify-content: center;

            &:first-child {
                margin-right: 0.5em;
            }
        }
    }
}
