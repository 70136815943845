.date-popper {
    z-index: 99;
}

.input {
    width: 100%;
    box-shadow: var(--box-shadow-xs);
    padding: 0.625em 1em;
    font-size: var(--font-size-text3);
    color: var(--color-gray-900);
    height: 2.75em;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-small);
}

.input-warning {
    border-color: var(--color-warning);
}

.warning-text {
    font-size: var(--font-size-text5);
    font-weight: 600;
    color: var(--color-warning);
}
