.container {
    .input-field {
        margin-bottom: 1.25em;
    }

    .exchange-rate-container {
        margin-bottom: 1.25em;
        padding-left: 1.25rem;

        .exchange-rate-label {
            font-size: var(--font-size-text4);
            font-weight: 500;
            line-height: 1.57;
            text-align: left;
            color: var(--color-gray-900);
            margin-bottom: 0.25em;
        }

        .exchange-rate-inputs {
            display: flex;
            justify-content: flex-start;
            gap: 0.5rem;

            .exchange-rate-input {
                max-width: 12rem;
            }
        }
    }
}

.budget-label {
    font-size: var(--font-size-text4);
    font-weight: 500;
    line-height: 1.57;
    text-align: left;
    color: var(--color-gray-900);
    margin-bottom: 0.25em;
}

.error-text {
    color: var(--color-danger);
    font-size: var(--font-size-text5);
    font-weight: 500;
}

.warning-text {
    color: var(--color-warning);
    font-size: var(--font-size-text5);
    font-weight: 500;
}

.input-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border: 1px solid var(--color-gray-300);
    border-radius: 4px;
    padding: 0.625rem 0;
    box-shadow: var(--box-shadow-xs);

    &-disabled {
        background-color: var(--color-gray-50);
    }

    &:not(.input-container-disabled) {
        &:focus-within {
            outline: solid var(--color-primary-400);
        }

        &:hover {
            border-color: var(--color-primary-400);
        }
    }

    .select {
        border-right: 1px solid var(--color-gray-300);

        .select-control {
            padding: 0 0.5rem 0 0.625rem;
            border: none;
            cursor: pointer;

            &-disabled {
                :global {
                    .select-indicator {
                        display: none;
                    }
                }
            }
        }
    }

    .amount-input-container {
        flex: 1;

        .input {
            border: none;
            padding: 0 0.625rem;
            height: unset;
        }
    }

    .input:hover,
    .input:focus {
        border: none;
        outline: none;
    }
}
