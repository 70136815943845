$padding-inline: 7.5vw;

.container {
    .header {
        .project-title-container {
            background-color: var(--color-primary-600);
            padding: 0 $padding-inline;

            @media screen and (min-width: 992px) {
                background: url('~assets/shapes/rectangle_sm_up.svg') 75% 0% no-repeat,
                    url('~assets/shapes/rectangle_med_down.svg') 100% 100% no-repeat,
                    url('~assets/shapes/circle_sm.svg') 88% 37% no-repeat,
                    url('~assets/shapes/circle_lg_qtr.svg') 100% 0 no-repeat;
                background-color: var(--color-primary-600);
            }

            .project-title {
                max-width: 750px;
                width: 75%;
                padding: 2.625rem 0;
                color: var(--color-white);
                font-size: var(--font-size-heading3);
                letter-spacing: -0.02em;
                line-height: 1.27em;

                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        .tabs {
            background-color: var(--color-white);

            .tab-header-container {
                display: flex;
                justify-content: space-between;
                padding: 0 $padding-inline;
                border-bottom: 1px solid var(--color-gray-200);

                .tab-header {
                    .tab-item {
                        padding: 0.75rem 1.5rem;
                        font-size: var(--font-size-text2);
                        font-weight: 700;
                        color: var(--color-gray-800);

                        &-active {
                            border-bottom-width: 3px;
                            color: var(--color-primary-600);
                            background-color: var(--color-primary-50);
                        }

                        &:not(:first-child) {
                            margin-left: 0;
                        }
                    }
                }

                .buttons {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    .add-report-button {
                        align-self: center;
                    }

                    .edit-button {
                        align-self: center;
                    }
                }
            }

            .tab-scroll-header {
                padding: 1rem $padding-inline;
                border-radius: 0;
                box-shadow: none;
                filter: drop-shadow(0 1px 2px rgb(16 24 40 / 5%));
                border-bottom: 1px solid var(--color-gray-200);
                position: sticky;
                z-index: 3;
            }

            .tab-scroll-content-container {
                padding: 3rem $padding-inline;
                background-color: var(--color-gray-100);
                flex: 1;

                .tab-scroll-content {
                    margin-bottom: 3rem;

                    &-header {
                        color: var(--color-gray-900);
                        font-size: var(--font-size-heading4);
                        line-height: 1.17em;
                        font-weight: 700;
                        letter-spacing: -0.02em;
                        margin-bottom: 1.25rem;
                    }

                    &-body {
                        background-color: var(--color-white);
                        border: 1px solid var(--color-gray-100);
                        box-shadow: var(--box-shadow-xs);
                        border-radius: 4px;
                        padding: 1.25rem;

                        .description {
                            color: var(--color-gray-800);
                            font-size: var(--font-size-text3);
                            line-height: 1.5em;
                            margin-bottom: 1.5rem;
                        }

                        .details-info-container {
                            .details-info-header {
                                color: var(--color-gray-500);
                                letter-spacing: 0.04em;
                                font-size: var(--font-size-text3);
                                line-height: 1.5em;
                                padding: 0.5rem 0;
                                border-bottom: 1px solid var(--color-gray-200);
                            }

                            .hazards,
                            .organizations {
                                margin: 1rem 0;
                                border: 1px solid var(--color-gray-200);
                                border-radius: 4px;
                                padding: 1rem;
                                display: flex;
                                justify-content: flex-start;
                                flex-flow: wrap;

                                .organization-item {
                                    width: unset;
                                }
                            }

                            .hazards {
                                gap: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.details-info {
    display: flex;
    flex-flow: wrap;
    gap: 1rem;
    margin: 1rem 0;

    &-item {
        display: flex;
        align-items: flex-start;
        flex: 1;
        padding: 0.75rem 1rem;
        border: 1px solid var(--color-gray-200);
        border-radius: 4px;
        min-width: fit-content;
        gap: 1rem;

        .info-item-icon {
            width: 3.25rem;
            height: 3.25rem;
            background-color: var(--color-primary-50);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: var(--color-primary-600);
                font-size: var(--font-size-heading4);
            }
        }

        .info-item-header {
            display: flex;
            align-items: center;
            margin-bottom: 0.25rem;

            &-title {
                color: var(--color-gray-500);
                font-size: var(--font-size-text4);
                line-height: 1.57em;
                font-weight: 600;

                &-map {
                    color: var(--color-gray-900);
                    font-size: var(--font-size-text3);
                    font-weight: 700;
                    line-height: 1.5em;
                }
            }

            .view-map-link {
                display: flex;
                align-items: center;
                color: var(--color-primary-600);
                font-size: var(--font-size-text3);
                margin-left: auto;
                cursor: pointer;
                transition: 0.2s ease-out;
                margin-bottom: 1rem;

                &:hover {
                    filter: brightness(110%);
                }

                &-icon {
                    font-size: var(--font-size-text2);
                }

                &-text {
                    margin-left: 0.25rem;
                    font-size: var(--font-size-text4);
                    font-weight: 600;
                    line-height: 1.57em;
                }
            }
        }

        .info-item-content-container {
            flex: 1;

            .info-item-content {
                color: var(--color-gray-900);
                font-size: var(--font-size-text3);
                font-weight: 700;
                line-height: 1.5em;
                width: 100%;
            }
        }
    }

    &-item-map {
        flex: 0 0 100%;
        display: unset;
    }
}

.detail-value {
    color: var(--color-gray-900);
    font-size: var(--font-size-text3);
    font-weight: 700;
    line-height: 1.5em;
}

.exchange-rate {
    background-color: var(--color-gray-100);
    border-radius: 4px;
    color: var(--color-gray-800);
    font-weight: 500;
    font-size: var(--font-size-text4);
    line-height: 1.57em;
    padding: 0.5rem;
    margin-top: 0.5rem;
}

.document-link {
    margin: 0.5rem 0;
}
