.modal {
    background-color: var(--color-white);
    width: 27.75em;
    max-width: 90%;
    box-shadow: var(--box-shadow-xl);
    border-radius: 0.5em;
    padding: 2em;

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25em;

        .lock-wrapper {
            height: 2.5em;
            width: 2.5em;
            border-radius: 50%;
            background-color: var(--color-primary-100);
            display: flex;
            justify-content: center;
            align-items: center;

            .lock-icon {
                color: var(--color-primary-600);
                font-size: var(--font-size-heading4);
            }
        }

        .close-icon {
            cursor: pointer;
            color: var(--color-gray-400);
            font-size: var(--font-size-heading4);
        }
    }

    .title {
        font-size: var(--font-size-text1);
        line-height: 2em;
        color: var(--color-gray-900);
    }

    .form-wrapper {
        .error-message {
            color: var(--color-danger);
            font-size: var(--font-size-text4);
            letter-spacing: 0.3px;
            font-weight: 500;
        }

        .input-label {
            font-size: var(--font-size-text4);
            color: var(--color-gray-600);
            font-weight: 500;
        }

        .button {
            margin-top: 2em;
            width: 100%;
        }
    }
}
