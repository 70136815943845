.container {
    height: 100%;
    overflow: hidden;

    .tabs {
        display: flex;
        height: 100%;

        .tabs-header {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            border-right: 1px solid var(--color-gray-200);
            background-color: var(--color-white);
            padding: 3.125rem 1.25rem;
            height: 100%;
            width: 20rem;

            @media screen and (max-width: 767px) {
                width: fit-content;
            }

            .header-item {
                padding: 0.75rem;
                cursor: pointer;
                border-radius: 4px;
                color: var(--color-gray-500);
                display: flex;
                align-items: center;
                gap: 0.625rem;
                transition: background-color 0.3s ease;

                .tab-title {
                    font-weight: 500;
                    font-size: var(--font-size-text3);
                    line-height: 1.5em;

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }

                &-active {
                    color: var(--color-primary-600);
                    background-color: var(--color-primary-50);

                    .tab-title {
                        font-weight: 600;
                    }
                }

                &:hover:not(.header-item-active) {
                    background-color: var(--color-gray-100);
                }
            }
        }

        .content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 6.5rem 0;
            height: 100%;
            overflow-y: auto;

            @media screen and (max-width: 767px) {
                padding: unset;
            }

            .tab-content {
                width: 32rem;
                max-width: 100%;
                background-color: var(--color-white);
                box-shadow: var(--box-shadow-xs);
                border-radius: 8px;
                padding: 2.5rem;
                display: flex;
                flex-direction: column;

                @media screen and (max-width: 767px) {
                    height: 100%;
                }
            }
        }
    }
}
