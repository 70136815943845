.reports-dropdown {
    box-shadow: var(--box-shadow-lg);
    border-radius: 4px;
    min-width: 16rem;

    .reports-dropdown-content {
        padding: 0.75rem 0.5rem;
        list-style: none;
        background-color: var(--color-white);
    }

    .reports-dropdown-item {
        padding: 0.625rem 0.75rem;
        cursor: pointer;
        border-radius: 4px;
        margin-bottom: 0.25em;

        &:hover {
            background-color: var(--color-gray-100);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
