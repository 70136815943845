.container {
    padding: 1.5rem 1.25rem;

    .filter-header {
        display: flex;
        margin-bottom: 0.75rem;
        justify-content: space-between;

        .filter-title {
            color: var(--color-gray-900);
            font-weight: 700;
            font-size: var(--font-size-text2);
            line-height: 1.56em;
        }

        .clear-link {
            cursor: pointer;
            font-size: var(--font-size-text4);
            font-weight: 500;
            line-height: 1.57em;
            color: var(--color-primary-600);
            max-width: fit-content;
            transition: 0.2s ease-out;

            &:hover {
                filter: brightness(115%);
            }
        }
    }

    .filters {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .date-filter-container {
            max-width: fit-content;

            .date-popper {
                z-index: 5;
            }

            :global {
                .react-datepicker__close-icon::after {
                    background-color: var(--color-gray-500);
                }

                .react-datepicker__day--in-range,
                .react-datepicker__day--keyboard-selected {
                    background-color: var(--color-primary);
                }

                .react-datepicker__day--in-selecting-range:not(
                        .react-datepicker__day--in-range,
                        .react-datepicker__day--keyboard-selected
                    ) {
                    background-color: var(--color-primary-400);
                }
            }
        }
    }

    .content {
        background-color: var(--color-white);
        padding: 1.25rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .content-group {
            display: flex;
            gap: 1.5rem;

            @media screen and (max-width: 1199px) {
                flex-direction: column;
            }

            .content-item {
                flex: 1 1 calc(50% - 1.5rem);
                border: 1px solid var(--color-gray-200);
                box-shadow: var(--box-shadow-xs);
                border-radius: 4px;
                padding: 1.5rem;

                &-title {
                    font-weight: 700;
                    font-size: var(--font-size-heading4);
                    line-height: 1.17em;
                    letter-spacing: -0.02em;
                    color: var(--color-gray-900);
                    margin-bottom: 1.875rem;

                    &-location {
                        font-size: var(--font-size-heading1);
                        line-height: 1.25em;
                    }
                }

                &-map {
                    background-color: var(--color-gray-100);
                    padding: 0;
                }

                &-stat {
                    padding: 0;
                    padding-top: 1rem;
                    border: 0;
                    box-shadow: none;
                }

                .stats {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1.25rem;

                    .stat-item {
                        flex: 1 1 calc(50% - 1.25rem);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border: 1px solid var(--color-primary-100);
                        box-shadow: var(--box-shadow-xs);
                        border-radius: 4px;
                        padding: 1.5rem;

                        &-icon-container {
                            background-color: var(--color-primary-400);
                            box-shadow: var(--box-shadow-xs);
                            width: 4.25rem;
                            height: 4.25rem;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 1.25rem;

                            .stat-item-icon {
                                color: var(--color-white);
                                text-shadow: var(--box-shadow-md);
                                font-size: 2.25rem;
                            }
                        }

                        &-label {
                            font-size: var(--font-size-text2);
                            font-weight: 700;
                            height: 1.56em;
                            color: var(--color-gray-500);
                            text-align: center;
                        }

                        &-value {
                            font-family: Inter, sans-serif;
                            text-align: center;
                            font-size: var(--font-size-display2);
                            font-weight: 700;
                            line-height: 1.13em;
                            letter-spacing: -0.02em;
                            color: var(--color-gray-800);
                        }
                    }
                }

                .chart-container {
                    width: 100%;
                }

                .info-text {
                    color: var(--color-gray-800);
                    font-size: var(--font-size-text4);
                }
            }
        }

        .content-item-table {
            border: 1px solid var(--color-gray-200);
            box-shadow: var(--box-shadow-xs);
            border-radius: 4px;
            padding: 1.5rem;

            .table-title {
                font-weight: 700;
                font-size: var(--font-size-heading4);
                line-height: 1.17em;
                letter-spacing: -0.02em;
                color: var(--color-gray-900);
                margin-bottom: 1.875rem;
            }

            .table-container {
                overflow: auto;
                border: 1px solid var(--color-gray-300);
                border-radius: 4px;
            }

            .button-container {
                display: flex;
                justify-content: center;
                margin-top: 1.75rem;
            }
        }
    }
}

.filter-list {
    background-color: var(--color-white);
    padding: 1rem 2.5rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.filter-item-list {
    padding: 0.5rem;
    background-color: var(--color-white);
    width: 33vw;
    max-width: 300px;
    min-width: fit-content;
    display: flex;
    flex-flow: wrap;
    gap: 0.5rem;
}

.filter-budget {
    width: 50vw;
    max-width: 360px;
    padding: 1rem 1.5rem;
    min-height: 8rem;

    &-active {
        min-height: 9rem;
    }
}
