.auth-container {
    background-color: var(--color-gray-100);
    min-height: 100vh;
    width: 100vw;
    padding: 10vh 0;
    display: flex;
    justify-content: center;

    .auth-box {
        height: fit-content;
        width: 100%;
        max-width: 31.93em;
        border-radius: 0.5em;
        padding: 2.5em;
        box-shadow: 0 1px 2px 0 #1018280d;
        background-color: var(--color-white);

        @media screen and (max-width: 768px) {
            width: 90%;
        }

        .auth-heading {
            font-size: var(--font-size-heading3);
            font-weight: 700;
            line-height: 1.27;
            letter-spacing: -0.0375em;
            text-align: center;
            color: var(--color-gray-900);
        }

        .auth-sub-heading {
            font-size: var(--font-size-text3);
            font-weight: 500;
            line-height: 1.5;
            text-align: center;
            color: var(--color-gray-500);
            margin: 0.75em 0 1.5em;
        }

        .form-wrapper {
            .details-heading {
                font-size: var(--font-size-text2);
                line-height: 1.556;
                color: var(--color-gray-800);
                padding: 0.5em 0;
                border-bottom: 1px solid var(--color-gray-200);
                margin-bottom: 1.5em;
            }

            .title-input-group {
                display: flex;
                align-items: center;
                gap: 1.25rem;

                .name-input-container {
                    flex: 1;
                }

                .acronym-input-container {
                    flex: 0 0 30%;
                }
            }

            .input-label {
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57;
                text-align: left;
                color: var(--color-gray-900);
                margin-bottom: 0.25em;
            }

            .logo-input-wrapper {
                margin-top: 0.5em;
                display: flex;
                gap: 1.5em;

                .logo-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .logo-container {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        .logo {
                            width: 4rem;
                            object-fit: contain;
                        }
                    }

                    .logo-action {
                        display: flex;
                        gap: 0.5em;

                        .delete-logo-button {
                            color: var(--color-gray-500);
                            border: 1px solid var(--color-gray-300);
                            background-color: var(--color-white);

                            &:hover {
                                background-color: var(--color-gray-50);
                            }
                        }
                    }
                }

                .logo-input {
                    width: 100%;
                }
            }

            .contact-input {
                display: inline-grid;
                width: 100%;
                margin-bottom: 1.25rem;

                .input-label {
                    font-size: var(--font-size-text4);
                    font-weight: 500;
                    line-height: 1.57;
                    text-align: left;
                    color: var(--color-gray-900);
                    margin-bottom: 0.25em;
                }

                .auth-input {
                    width: 100%;
                    display: flex;
                    margin-bottom: 1.25em;

                    .input {
                        box-shadow: 0 1px 2px 0 #1018280d;
                    }
                }
            }

            .select-input {
                width: 100%;
                margin-bottom: 1.25em;
            }

            .file-input {
                width: 100%;
                margin-bottom: 1.25em;
                display: flex;
                flex-direction: column;

                .file-input-label {
                    display: inline-block;
                    font-size: var(--font-size-text4);
                    font-weight: 500;
                    line-height: 1.57;
                    text-align: left;
                    color: var(--color-gray-900);
                    margin-bottom: 0.25em;
                }

                .textarea {
                    padding: 0.625em 1em;
                    font-size: var(--font-size-text3);
                    line-height: 1.5em;
                    color: var(--color-gray-900);
                    width: 100%;
                    border: 1px solid var(--color-border);
                    background-color: inherit;
                    border-radius: var(--border-radius-small);

                    &::placeholder {
                        color: var(--color-gray-400);
                    }

                    &:-ms-input-placeholder {
                        color: var(--color-gray-400);
                    }

                    &::-ms-input-placeholder {
                        color: var(--color-gray-400);
                    }

                    &:focus:not(:disabled),
                    &:hover:not(:disabled) {
                        border: 1px solid var(--color-input-outline);
                        outline-color: var(--color-input-outline);
                    }
                }
            }

            .error-message,
            .error-message * {
                color: var(--color-error-700);
                text-align: center;
                font-size: var(--font-size-text3);
                font-weight: 500;
                margin-top: 1rem;
            }

            .view-button {
                margin-bottom: 2.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.25em;
                background-color: var(--color-gray-100);
                padding: 0.5em;
                font-size: var(--font-size-text4);
                line-height: 1.57;
                color: var(--color-gray-700);
                gap: 0.5em;
                font-weight: 500;

                span {
                    font-size: var(--font-size-text4);
                }

                &:hover {
                    background-color: var(--color-primary-50);
                }
            }

            .msg-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .checkWrapper {
                    display: flex;
                    align-items: center;

                    .message {
                        margin-left: 0.5em;
                        font-size: var(--font-size-text4);
                        font-weight: 500;
                        line-height: 1.57;
                        text-align: center;
                        color: var(--color-gray-700);
                    }
                }
            }

            .auth-button {
                width: 100%;
                margin: 2em 0;
                justify-content: center;
            }
        }

        .link-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .register-message {
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57;
                text-align: center;
                color: var(--color-gray-500);
            }

            .register-link {
                font-size: var(--font-size-text4);
                font-weight: 600;
                line-height: 1.57;
                text-align: center;
                color: var(--color-primary-600);
                padding: 0.5em;
                text-decoration: none;
            }
        }
    }
}
