.table {
    border-radius: 4px;
    border-collapse: separate;
    border-spacing: 0;
    border-width: 0;

    .table-message {
        padding: 1rem;
    }

    .header {
        border: none;
        border-radius: 4px;

        th {
            border: none;
            padding-left: 1rem;
            border-bottom: 1px solid var(--color-gray-300);

            .header-item {
                text-align: start;
                display: flex;
                align-items: center;
                color: var(--color-gray-800);
                font-weight: 700;

                &-icon {
                    font-size: var(--font-size-text3);
                    color: var(--color-gray-400);
                    cursor: pointer;
                    transition: filter 0.2s ease-out;
                    padding: 0 2px;

                    &:hover {
                        filter: brightness(70%);
                    }

                    &-active {
                        font-size: var(--font-size-text2);
                        color: var(--color-gray-600);
                    }
                }
            }

            &:first-child {
                width: 36%;
                border-radius: 4px 0 0;
            }

            &:last-child {
                border-radius: 0 4px 0 0;
            }

            &:nth-child(2):last-child {
                width: 17%;
            }

            &:nth-child(3):nth-child(4):nth-child(5) {
                width: 10%;
            }
        }
    }

    .body-row {
        background-color: var(--color-white);
        cursor: pointer;

        .title-item {
            font-weight: 700;
            font-size: var(--font-size-text4);
            line-height: 1.57em;
            color: var(--color-gray-800);
        }

        td {
            border-width: 1px 0;
            border-style: solid;
            border-color: var(--color-gray-200);
        }

        &:hover {
            background-color: var(--color-primary-50);

            .title-item {
                color: var(--color-primary-600);
            }

            td {
                border-color: var(--color-primary-200);
            }
        }

        &:last-child {
            td {
                border-bottom-width: 0;

                &:first-child {
                    border-radius: 0 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 0 4px;
                }
            }
        }

        .table-data {
            padding: 1rem;
        }

        &:nth-child(even):not(:hover) {
            background-color: var(--color-gray-50);
        }
    }
}
