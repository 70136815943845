.lds-loader {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 24px;
}

.lds-loader div {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 8px;
    background: var(--color-white);
    animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-loader-primary div {
    background: var(--color-primary-400);
}

.lds-loader-dark div {
    background: var(--color-gray-400);
}

.lds-loader div:nth-child(1) {
    left: 0;
    animation-delay: -0.24s;
}

.lds-loader div:nth-child(2) {
    left: 12px;
    animation-delay: -0.12s;
}

.lds-loader div:nth-child(3) {
    left: 24px;
    animation-delay: 0;
}

@keyframes lds-loader {
    0% {
        top: -4px;
        height: 32px;
    }

    50%,
    100% {
        top: 4px;
        height: 16px;
    }
}
