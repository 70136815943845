.popup-label {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 0.625em 0.5em;
    cursor: pointer;
    transition: 0.3s ease-out;

    &:hover,
    &:focus {
        background-color: var(--color-gray-100);
    }

    &-active {
        background-color: var(--color-gray-50);
    }

    .language-icon {
        color: var(--color-gray-400);
    }

    .language-code {
        font-weight: 600;
        font-size: var(--font-size-text3);
        color: var(--color-gray-700);
        margin: 0 0.2em;
    }

    .dropdown-icon {
        color: var(--color-gray-700);
    }
}

.language-popup {
    box-shadow: var(--box-shadow-lg);
    border-radius: 4px;

    .language-dropdown {
        border: 1px solid var(--color-gray-100);
        margin-top: 0.375em;
        padding: 0.75em 0.5em;
        list-style: none;
        background-color: var(--color-white);
        min-width: 200px;
        border-radius: 4px;
    }

    .language-item {
        padding: 0.625em 0.75em;
        cursor: pointer;
        border-radius: 4px;
        margin-bottom: 0.25em;

        &:hover {
            background-color: var(--color-gray-100);
        }

        &:last-child {
            margin-bottom: 0;
        }

        .language-title {
            color: var(--color-gray-900);
        }

        .language-code {
            color: var(--color-gray-500);
            margin-left: 0.375em;
        }
    }
}
