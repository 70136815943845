.legend-collapsed {
    border: 1px solid var(--color-gray-200);
    box-shadow: var(--box-shaodw-xs);
    background-color: var(--color-white);
    padding: 0.75em;
    display: flex;
    align-items: center;
    border-radius: 4px;

    .legend-text {
        font-size: var(--font-size-text3);
        line-height: 1.6em;
        font-weight: 700;
        color: var(--color-gray-700);
    }

    .control-icon {
        margin-left: 1em;
        cursor: pointer;
        color: var(--color-gray-500);

        &:hover {
            filter: brightness(115%);
        }
    }
}

.legend-box {
    background-color: var(--color-white);
    padding: 0.75em;
    border: 1px solid var(--color-gray-200);
    box-shadow: var(--box-shaodw-xs);
    border-radius: 4px;

    .legend-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1em;

        .legend-control-icon {
            cursor: pointer;
            color: var(--color-gray-500);

            &:hover {
                filter: brightness(115%);
            }
        }
    }

    .legend-label {
        font-size: 0.575rem;
        font-weight: 600;
        color: var(--color-gray-700);
    }
}
