.modal {
    border-radius: 8px;
    width: 45rem;
    max-width: 90vw;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-xl);
    padding: 1.25rem 1rem;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 2rem;
        padding: 0 0.25rem;

        .title {
            font-size: var(--font-size-heading3);
            font-weight: 700;
            line-height: 1.27em;
            letter-spacing: -0.02em;
            color: var(--color-gray-800);
            width: 90%;
        }

        .close-icon {
            color: var(--color-gray-400);
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
                color: var(--color-gray-500);
            }
        }
    }

    .content {
        max-height: 75vh;
        overflow-y: auto;
        position: relative;

        .tab-header {
            position: sticky;
            top: 0;
            box-shadow: unset;
            border-radius: unset;
            padding: 1rem 0;
            border-bottom: 1px solid var(--color-gray-200);
        }

        .tab-content-container {
            padding-top: 1.25rem;

            .report-title {
                font-size: var(--font-size-text1);
                font-weight: 600;
                line-height: 1.6em;
                color: var(--color-gray-900);
            }

            .report-description {
                margin: 1rem 0;
                font-size: var(--font-size-text3);
                line-height: 1.5em;
            }

            .report-info {
                display: flex;
                flex-flow: wrap;
                gap: 1rem;
                margin-bottom: 2rem;
            }

            .report-row {
                display: flex;
                flex-flow: wrap;
                gap: 1rem;
                margin-bottom: 2rem;

                .content-item-container {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .content-title {
                        font-size: var(--font-size-text3);
                        line-height: 1.5em;
                        letter-spacing: 0.04em;
                        font-weight: 700;
                        color: var(--color-gray-500);
                        margin-bottom: 1rem;
                    }

                    .content-item {
                        border: 1px solid var(--color-gray-200);
                        border-radius: 4px;
                        padding: 1rem;

                        &-value {
                            font-size: var(--font-size-text3);
                            line-height: 1.5em;
                            color: var(--color-gray-900);
                            font-weight: 700;
                            margin-bottom: 0.75rem;
                        }

                        .organization-item {
                            width: unset;
                        }
                    }
                }
            }
        }
    }
}

.info-item-container {
    flex: 1;
    display: flex;
    align-items: flex-start;
    border: 1px solid var(--color-gray-200);
    border-radius: 4px;
    padding: 1rem;
    width: fit-content;
    min-width: 19rem;
    gap: 1rem;

    .info-item-icon-container {
        width: 3.25rem;
        height: 3.25rem;
        background-color: var(--color-primary-50);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .info-item-icon {
            color: var(--color-primary-600);
            font-size: var(--font-size-heading4);
        }
    }

    .info-item-content {
        flex-direction: column;

        &-key {
            font-size: var(--font-size-text4);
            line-height: 1.57em;
            color: var(--color-gray-500);
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        &-value {
            font-size: var(--font-size-text3);
            line-height: 1.5em;
            color: var(--color-gray-900);
            font-weight: 700;
        }

        &-footer {
            margin-top: 0.75rem;
        }
    }
}
