.expand-icon {
    cursor: pointer;
    transition: 0.3s ease-out;

    &:hover {
        color: var(--color-gray-500);
    }
}

.content {
    overflow: hidden;
    transition: max-height 0.3s ease;
}
