.steps-section {
    background: var(--color-gray-50);

    .section-title {
        font-weight: 700;
        font-size: var(--font-size-heading1);
        color: var(--color-gray-900);
        text-align: center;
        line-height: 1.25;
        letter-spacing: -0.96px;
    }

    .section-desc {
        max-width: 550px;
        font-weight: 500;
        font-size: var(--font-size-text1);
        color: var(--color-gray-700);
        margin: 1em auto 3em;
        text-align: center;
        line-height: 1.6;
    }

    .steps-list {
        display: flex;
        flex-direction: row;
        gap: 1.25em;

        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            gap: 2em;
            justify-content: center;
        }

        .step-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            background-color: var(--color-white);
            box-shadow: 0 1px 3px rgb(16 24 40 / 10%), 0 1px 2px rgb(16 24 40 / 6%);
            padding: 2.5em 1.75em;

            @media screen and (max-width: 1024px) {
                flex: unset;
                width: 45%;
            }

            @media screen and (max-width: 480px) {
                width: 90%;
            }

            .position {
                color: var(--color-primary-600);
                font-weight: 700;
                font-size: var(--font-size-heading1);
                line-height: 1.25;
                letter-spacing: -0.96px;
                border-bottom: 8px solid var(--color-primary-600);
                padding-bottom: 0.4em;
                margin-bottom: 0.4em;
            }

            .content {
                display: flex;
                flex-direction: column;
                padding: 0;

                .title-group {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 1.5em;

                    .step-icon {
                        font-size: var(--font-size-heading3);
                        color: var(--color-gray-400);
                    }

                    .title {
                        margin-left: 1.18em;
                        margin-left: 19px;
                        font-weight: 600;
                        font-size: var(--font-size-heading3);
                        line-height: 1.267;
                        color: var(--color-gray-900);
                        letter-spacing: -0.6px;
                    }
                }

                .sub-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: var(--font-size-text2);
                    color: var(--color-gray-700);
                    margin-bottom: 1em;
                }

                .point {
                    .point-item {
                        display: flex;
                        gap: 0.75em;
                        margin-bottom: 1em;

                        &:last-child {
                            padding-bottom: 0;
                        }

                        .point-list-icon {
                            margin-top: 5px;
                            height: 1em;
                            width: 1em;
                            min-width: 1em;

                            @media screen and (max-width: 480px) {
                                height: 0.75em;
                            }
                        }

                        .point-list-title {
                            color: var(--color-gray-600);
                            font-weight: 500;
                            font-size: var(--font-size-text3);
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
}
