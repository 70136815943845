.container {
    .section-title {
        font-size: var(--font-size-heading4);
        font-weight: 700;
        line-height: 1.17em;
        letter-spacing: -0.02em;
        color: var(--color-gray-900);
        margin-bottom: 1.25rem;
    }

    .section-content {
        background-color: var(--color-white);
        border: 1px solid var(--color-gray-100);
        box-shadow: var(--box-shadow-xs);
        border-radius: 8px;
        padding: 1.25rem;
        margin-bottom: 3rem;

        .output-title {
            color: var(--color-gray-900);
            font-weight: 600;
            font-size: var(--font-size-text1);
            line-height: 1.6em;
            margin-bottom: 1rem;
        }

        .activity-title {
            font-size: var(--font-size-text3);
            line-height: 1.5em;
            font-weight: 600;
            color: var(--color-gray-900);
            margin-bottom: 1.25rem;
        }

        .location-container {
            margin-top: 1.25rem;
            margin-bottom: 3rem;

            .location-title {
                font-size: var(--font-size-text3);
                letter-spacing: 0.04em;
                line-height: 1.5em;
                color: var(--color-gray-500);
                padding: 0.5rem 0;
                border-bottom: 1px solid var(--color-gray-200);
                margin-bottom: 1rem;
            }

            .map-container {
                padding: 0.75rem 1rem;
                border: 1px solid var(--color-gray-200);
                border-radius: 4px;

                .map-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.25rem;

                    .map-title {
                        color: var(--color-gray-900);
                        font-size: var(--font-size-text3);
                        font-weight: 700;
                        line-height: 1.5em;
                    }

                    .view-map-link {
                        display: flex;
                        align-items: center;
                        color: var(--color-primary-600);
                        font-size: var(--font-size-text3);
                        margin-left: auto;
                        cursor: pointer;
                        transition: 0.2s ease-out;
                        margin-bottom: 1rem;

                        &:hover {
                            filter: brightness(110%);
                        }

                        &-icon {
                            font-size: var(--font-size-text2);
                        }

                        &-text {
                            margin-left: 0.25rem;
                            font-size: var(--font-size-text4);
                            font-weight: 600;
                            line-height: 1.57em;
                        }
                    }
                }
            }
        }

        .section-item-title {
            font-size: var(--font-size-text1);
            margin-bottom: 1.5rem;
        }

        .priority-indicator-tabs {
            margin-bottom: 3rem;
        }

        .sub-section-title {
            font-size: var(--font-size-text3);
            line-height: 1.5em;
            letter-spacing: 0.04em;
            font-weight: 700;
            color: var(--color-gray-500);
            margin-bottom: 1rem;
        }

        .sub-section-content {
            border-radius: 4px;
            border: 1px solid var(--color-gray-200);
            padding: 1.25rem;
            margin-bottom: 2rem;
        }
    }
}

.info-item {
    display: flex;
    align-items: flex-start;
    padding: 0.75rem 1rem;
    border: 1px solid var(--color-gray-200);
    border-radius: 4px;
    width: fit-content;
    gap: 1rem;
    min-width: 19rem;

    .info-item-icon-container {
        width: 3.25rem;
        height: 3.25rem;
        background-color: var(--color-primary-50);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        .info-item-icon {
            color: var(--color-primary-600);
            font-size: var(--font-size-heading4);
        }
    }

    .info-item-content {
        flex-direction: column;

        &-key {
            color: var(--color-gray-500);
            font-size: var(--font-size-text4);
            line-height: 1.57em;
            font-weight: 600;
            margin-bottom: 0.25rem;
        }

        &-value {
            color: var(--color-gray-900);
            font-size: var(--font-size-text3);
            font-weight: 700;
            line-height: 1.5em;
        }
    }
}

.tabs-header-container {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    margin: 1.25rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-gray-200);

    .tabs-header {
        display: flex;
        overflow-x: unset;
        margin-right: 1rem;
        min-width: max-content;
        background-color: unset;

        .header-tab-item {
            margin-right: 0.5rem;
            background-color: var(--color-white);
            color: var(--color-gray-800);
            padding: 0.75rem 2.25rem;
            border: 1px solid var(--color-gray-300);
            border-radius: 52px;
            transition: border-color 0.3s ease;
            font-size: var(--font-size-text3);
            font-weight: 600;
            line-height: 1.5em;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &-active {
                border-color: var(--color-primary-600);
                color: var(--color-primary-600);
            }

            &:hover:not(.header-tab-item-active) {
                border-color: var(--color-gray-400);
            }
        }
    }
}

.priority-tabs-header-container {
    .priority-tabs-header {
        border-bottom: 1px solid var(--color-gray-300);
        gap: 2.5rem;

        .priority-header-tab-item {
            font-size: var(--font-size-text3);
            font-weight: 700;
            line-height: 1.5em;
            letter-spacing: 0.04em;
            color: var(--color-gray-500);
            padding-bottom: 0.5rem;

            &-active {
                color: var(--color-primary-600);
                border-bottom: 2px solid var(--color-primary-600);
            }
        }
    }
}

.priority-indicators {
    padding-top: 1rem;

    .indicator-item {
        display: flex;
        align-items: flex-start;
        flex-flow: wrap;
        padding: 1rem 0;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--color-gray-200);
        }

        &-title {
            flex: 0 0 22%;
            font-size: var(--font-size-text3);
            color: var(--color-gray-500);
            line-height: 1.5em;

            @media screen and (max-width: 767px) {
                margin-bottom: 0.25rem;
            }
        }

        &-value {
            flex: 1;
            font-size: var(--font-size-text3);
            color: var(--color-gray-900);
            line-height: 1.5em;
        }
    }
}
