.button {
    font-size: var(--font-size-text3);
    font-weight: 600;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-600);
    outline-color: var(--color-primary-900);
    border: none;
    color: var(--color-white);
    padding: 0.625rem 0.75rem;
    border-radius: 4px;
    filter: none !important;
    text-align: center;
    min-width: max-content;

    &:hover {
        background-color: var(--color-primary-800);
    }

    &-disabled {
        background-color: var(--color-primary-200);
    }

    &-large {
        font-size: var(--font-size-text2);
        padding: 0.75rem 1rem;
    }

    &-small {
        font-size: var(--font-size-text4);
        padding: 0.5rem 0.75rem;
        font-weight: 500;
    }

    &-secondary {
        background-color: var(--color-primary-50);
        border: 1px solid var(--color-primary-200);
        outline-color: var(--color-primary-400);
        color: var(--color-primary-600);
    }

    &-secondary-disabled {
        border: 1px solid var(--color-primary-100);
        color: var(--color-primary-400);
    }

    &-secondary:hover {
        background-color: var(--color-primary-100);
    }

    &-tertiary {
        background-color: var(--color-white);
        border: 1px solid var(--color-gray-300);
        outline-color: var(--color-gray-400);
        color: var(--color-gray-500);
    }

    &-tertiary-disabled {
        color: var(--color-gray-300);
    }

    &-tertiary:hover {
        background-color: var(--color-gray-50);
    }

    &-accent {
        background-color: var(--color-secondary-600);
        outline-color: var(--color-secondary-900);
        color: var(--color-white);
    }

    &-accent-disabled {
        background-color: var(--color-secondary-200);
    }

    &-accent:hover {
        background-color: var(--color-secondary-800);
    }

    .left-icon {
        margin-right: 0.45em;
        font-size: 1.25em;
    }

    .right-icon {
        margin-left: 0.45em;
        font-size: 1.25em;
    }
}
