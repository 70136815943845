.form-section {
    &:not(:last-child) {
        margin-bottom: 2.5rem;
    }

    .form-section-title {
        border-bottom: 1px solid var(--color-gray-200);
        padding: 0.75rem 0;
        margin-bottom: 1rem;
        color: var(--color-gray-600);
        font-size: var(--font-size-text3);
        line-height: 1.5em;
        text-transform: uppercase;
    }

    .input-label {
        font-size: var(--font-size-text4);
        font-weight: 500;
        line-height: 1.57;
        text-align: left;
        color: var(--color-gray-600);
        margin-bottom: 0.25em;
    }

    .select-input {
        width: 100%;
        margin-bottom: 1.25em;
    }

    .textarea {
        padding: 0.625em 1em;
        font-size: var(--font-size-text3);
        line-height: 1.5em;
        color: var(--color-gray-900);
        width: 100%;
        border: 1px solid var(--color-border);
        background-color: inherit;
        border-radius: var(--border-radius-small);

        &::placeholder {
            color: var(--color-gray-400);
        }

        &:-ms-input-placeholder {
            color: var(--color-gray-400);
        }

        &::-ms-input-placeholder {
            color: var(--color-gray-400);
        }

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            border: 1px solid var(--color-input-outline);
            outline-color: var(--color-input-outline);
        }
    }

    .tabs-header-container {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
        margin-bottom: 1rem;

        .tabs-header {
            display: flex;
            overflow-x: unset;
            margin-right: 1rem;
            min-width: max-content;

            .header-tab-item {
                margin-right: 0.5rem;
                background-color: var(--color-white);
                color: var(--color-gray-800);
                padding: 0.75rem 2.25rem;
                border: 1px solid var(--color-gray-300);
                border-radius: 52px;
                transition: border-color 0.3s ease;
                font-size: var(--font-size-text3);
                font-weight: 600;
                line-height: 1.5em;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 0.5rem;

                .remove-icon {
                    font-size: var(--font-size-text1);
                }

                &-active {
                    border-color: var(--color-primary-600);
                    color: var(--color-primary-600);
                }

                &:hover:not(.header-tab-item-active) {
                    border-color: var(--color-gray-400);
                }
            }
        }

        .add-button {
            display: flex;
            align-items: center;
            color: var(--color-gray-500);
            gap: 0.5rem;
            transition: 0.3s ease-out;
            cursor: pointer;
            min-width: max-content;
            font-size: var(--font-size-text3);
            font-weight: 600;
            line-height: 1.5em;

            &:hover {
                color: var(--color-gray-600);
            }
        }
    }
}

.form-error-message {
    color: var(--color-danger);
    text-align: center;
}

.input-spacing {
    margin-bottom: 1.25em;
}
