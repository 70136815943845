.title {
    text-align: center;
    color: var(--color-gray-900);
    letter-spacing: -0.02em;
    font-size: var(--font-size-heading4);
    margin-bottom: 2rem;
}

.input-label {
    font-size: var(--font-size-text4);
    font-weight: 500;
    line-height: 1.57;
    text-align: left;
    color: var(--color-gray-900);

    &-select {
        margin-bottom: 0.25em;
    }
}

.select-input {
    background-color: var(--color-gray-200);
}

.email-input {
    margin-bottom: 0.25rem;
}

.button {
    margin-top: 2rem;
    width: 100%;
}

.error-message,
.error-message * {
    color: var(--color-error-700);
    text-align: center;
    font-size: var(--font-size-text3);
    font-weight: 500;
    margin: 1rem auto 0;
}
