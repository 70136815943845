.marqueeContainer {
    overflow-x: hidden;
    display: flex;
    position: relative;
    width: 100%;
    margin-top: 4.5em;

    @media screen and (max-width: 1024px) {
        margin-top: 4em;
    }

    @media screen and (max-width: 480px) {
        margin-top: 3em;
    }

    &:hover div {
        animation-play-state: paused;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;

        &::before,
        &::after {
            background: linear-gradient(to right, rgba(#fff, 1), rgba(#fff, 0.5), rgba(#fff, 0.02));
            content: '';
            height: 100%;
            position: absolute;
            width: 200px;
            z-index: 2;

            @media screen and (max-width: 1024px) {
                width: 100px;
            }

            @media screen and (max-width: 768px) {
                width: 75px;
            }
        }

        &::after {
            right: 0;
            top: 0;
            transform: rotateZ(180deg);
        }

        &::before {
            left: 0;
            top: 0;
        }
    }

    .marquee {
        flex: 0 0 auto;
        min-width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        animation: marquee var(--duration) linear infinite;
        animation-play-state: running;
        animation-delay: 0;
        animation-direction: var(--direction);

        @keyframes marquee {
            0% {
                transform: translateX(0%);
            }

            100% {
                transform: translateX(-100%);
            }
        }
    }
}
