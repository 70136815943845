.icon-container {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container-approved {
    background-color: var(--color-primary-100);

    .approved-icon {
        padding: 0.5rem;
        color: var(--color-primary-600);
        font-size: var(--font-size-heading4);
    }
}

.icon-container-decline {
    background-color: var(--color-secondary-100);

    .decline-icon {
        padding: 0.5rem;
        color: var(--color-secondary-600);
        font-size: var(--font-size-heading4);
    }
}

.notification-icon-container {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-gray-200);

    .active-notifications {
        position: absolute;
        color: var(--color-white);
        background-color: var(--color-error-500);
        top: -6px;
        right: -6px;
        font-size: 12px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        text-align: center;
        line-height: 1em;
        vertical-align: center;
    }

    .notification-icon {
        padding: 0.5rem;
        color: var(--color-gray-600);
    }
}
