.overview-box {
    box-shadow: var(--box-shadow-xl);
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-white);
    padding: 1em;
    border-radius: 4px;
    width: 32rem;
    max-width: 80vw;

    &-collapsed {
        width: max-content;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: var(--font-size-text2);
            line-height: 1.6em;
            font-weight: 700;
            color: var(--color-gray-700);
        }

        .control-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--color-gray-100);
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.75rem;
            transition: filter 0.2s ease-out;
            cursor: pointer;

            &-collapsed {
                margin-left: 1em;
            }

            &:hover {
                filter: brightness(95%);
            }

            .control-icon {
                color: var(--color-gray-500);
            }
        }
    }

    .data-items {
        display: flex;
        gap: 0.5rem;
        margin: 1.25em 0;
    }

    .top-data-section {
        .top-data-section-title {
            color: var(--color-gray-500);
            font-size: var(--font-size-500);
            line-height: 1.5em;
            font-weight: 600;
            margin-bottom: 0.25rem;
        }

        .tick-label {
            font-size: var(--font-size-text5);
            color: var(--color-gray-700);
            cursor: text;
        }

        .empty-text {
            margin: 1.25rem 0;
            font-size: var(--font-size-text5);
            color: var(--color-gray-500);
        }
    }
}
