.policy-tabs {
    margin: 2rem auto;
    max-width: 90%;

    .loader-wrapper {
        margin: 16vh 0;
        display: flex;
        justify-content: center;
    }

    .tabs-header-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .tabs-header-item {
            text-transform: uppercase;
        }
    }

    .tabs-content {
        padding: 2rem 0;
        min-height: 21vh;
    }
}
