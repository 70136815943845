.stat-card {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-primary-100);
    border-radius: 0.5em;
    padding: 2em 1.5em;
    transition: 0.4s;

    &:hover {
        transform: scale(1.01);
        border-color: var(--color-primary-200);
    }

    .iconWrapper {
        height: 4.25em;
        width: 4.25em;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-primary-400);

        .stats-icon {
            font-size: 36px;
            color: var(--color-white);
        }
    }

    .data {
        margin-left: 1.25em;
        display: flex;
        flex-direction: column;

        .stats-value {
            font-size: var(--font-size-display2);
            font-weight: 700;
            color: var(--color-gray-900);
            line-height: 1.13;
            letter-spacing: -1.2px;
        }

        .stats-title {
            font-size: var(--font-size-text2);
            font-weight: 700;
            color: var(--color-gray-500);
            line-height: 1.556;
            text-transform: uppercase;
        }
    }
}
