.footer-wrapper {
    background-color: #121645;

    .link-content {
        padding: 5em 0;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .heading {
            font-size: var(--font-size-text3);
            font-weight: 600;
            line-height: 1.5;
            color: var(--color-primary-400);
            margin-bottom: 1.5em;

            @media screen and (max-width: 768px) {
                font-size: var(--font-size-text1);
                margin-top: 1em;
                margin-bottom: 0.75em;
            }
        }

        .info-wrapper {
            @media screen and (max-width: 1024px) {
                width: 100%;
                margin-bottom: 3em;
                display: flex;
                align-items: center;
            }

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }

            .logo {
                margin-bottom: 3em;

                @media screen and (max-width: 1024px) {
                    margin-bottom: 1em;
                    margin-right: 1em;
                }

                @media screen and (max-width: 768px) {
                    margin-bottom: 3em;
                }
            }

            .text-info {
                max-width: 27em;
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57;
                color: var(--color-primary-100);

                @media screen and (max-width: 1024px) {
                    max-width: 100%;
                }

                @media screen and (max-width: 768px) {
                    max-width: 35em;
                }
            }
        }

        .quick-links {
            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .quick-link {
                display: flex;
                align-items: center;
                margin-bottom: 1.25em;
                max-width: max-content;

                &:last-child {
                    padding-bottom: unset;
                }

                .quick-link-title {
                    font-size: var(--font-size-text3);
                    font-weight: 500;
                    line-height: 1.5;
                    color: var(--color-primary-100);
                }

                .quick-link-icon {
                    margin-left: 0.25em;
                    font-size: var(--font-size-text3);
                    color: var(--color-primary-400);
                }

                &:hover {
                    .quick-link-title {
                        text-decoration: underline;
                    }
                }
            }
        }

        .useful-resource {
            display: flex;
            flex-direction: column;

            .resource-link {
                font-size: var(--font-size-text3);
                font-weight: 500;
                line-height: 1.5;
                color: var(--color-primary-100);
                margin-bottom: 1em;

                &:hover {
                    text-decoration: underline;
                }

                &:last-child {
                    padding-bottom: unset;
                }
            }
        }

        .contact-info {
            max-width: 20em;

            .contact-name {
                font-size: var(--font-size-text3);
                font-weight: 500;
                line-height: 1.5;
                color: var(--color-primary-100);
            }

            .contact-po-box {
                margin-top: 1em;
                font-size: var(--font-size-text3);
                font-weight: 500;
                line-height: 1.5;
                color: var(--color-primary-100);
            }

            .contact-link-wrapper {
                @media screen and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .contact-link {
                    margin-top: 0.75em;
                    display: flex;
                    align-items: center;

                    .contact-icon {
                        font-size: var(--font-size-text3);
                        color: var(--color-primary-400);
                        margin-right: 0.75em;
                    }

                    .contact-link-title {
                        font-size: var(--font-size-text3);
                        font-weight: 500;
                        line-height: 1.5;
                        color: var(--color-primary-100);
                    }
                }
            }
        }
    }

    .hairline {
        border: none;
        border-top: 1px solid rgba(#fff, 0.2);
    }

    .copyright-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.5em 0;

        .copyright {
            font-size: var(--font-size-text4);
            font-weight: 500;
            line-height: 1.57;
            color: var(--color-primary-400);
        }

        .legal-links {
            display: flex;
            align-items: center;

            .legal-link {
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57;
                color: var(--color-primary-400);
                margin-right: 1em;

                &:last-child {
                    padding-left: unset;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
