.container {
    border: 1px solid var(--color-gray-300);
    border-radius: 4px;
    overflow: hidden;

    .table {
        border-radius: 4px;
        border-collapse: separate;
        border-spacing: 0;
        border-width: 0;

        .table-message {
            padding: 1rem;
        }

        .header {
            border: none;
            border-radius: 4px;

            th {
                border: none;
                border-bottom: 1px solid var(--color-gray-300);
                text-align: start;
                color: var(--color-gray-800);
                font-weight: 700;
                padding: 0.75rem 1rem;

                &:first-child {
                    width: 50%;
                }

                &:last-child {
                    width: 30%;
                }
            }
        }

        .body-row {
            background-color: var(--color-white);

            .title-item {
                font-size: var(--font-size-text4);
                line-height: 1.57em;
                color: var(--color-gray-800);
            }

            .action-links {
                display: flex;
                align-items: center;
                gap: 2rem;

                .action-link {
                    cursor: pointer;
                    padding: 0.5rem 0;
                    color: var(--color-primary-600);
                    transition: filter 0.2s ease-out;

                    &:hover {
                        filter: brightness(90%);
                    }

                    &-disabled {
                        cursor: not-allowed;
                    }
                }
            }

            td {
                padding: 1rem;
                border-width: 1px 0;
                border-style: solid;
                border-color: var(--color-gray-200);
                font-weight: 500;

                &:first-child {
                    width: 50%;
                }

                &:last-child {
                    width: 30%;
                }
            }
        }
    }
}
