.list {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    gap: 2rem;
}

.organization-item {
    margin-right: 2.5rem;
    width: 7.5rem;

    .organization-logo {
        height: 5.5rem;
        max-width: 7.5rem;
        object-fit: contain;
        margin-bottom: 1.25rem;
    }

    .organization-title {
        color: var(--color-gray-900);
        font-size: var(--font-size-text3);
        font-weight: 700;
        line-height: 1.5em;
        align-self: flex-end;
    }
}

.placeholder-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrapper {
        height: 4.25em;
        width: 4.25em;
        max-height: 100%;
        max-width: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-primary-400);

        .placeholder-icon {
            font-size: 36px;
            color: var(--color-white);
        }
    }
}
