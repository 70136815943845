.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;

    .title {
        font-size: var(--font-size-text1);
        font-weight: 700;
        line-height: 1.6em;
        color: var(--color-gray-900);
    }

    .close-icon-container {
        cursor: pointer;
        padding: 0.375rem;
        background-color: var(--color-primary-50);
        border: 1px solid var(--color-primary-200);
        border-radius: 50%;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-primary-600);
        transition: 0.2s ease-in;

        &:hover {
            background-color: var(--color-primary-100);
        }
    }
}

.project-info-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &-header {
        font-size: var(--font-size-text4);
        font-weight: 700;
        line-height: 1.57em;
        color: var(--color-gray-900);
        border-bottom: 1px solid var(--color-gray-300);
        padding-bottom: 0.57em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;

        &-title {
            font-size: var(--font-size-text4);
            font-weight: 700;
            line-height: 1.57em;
            color: var(--color-gray-900);
        }
    }

    .project-info {
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;

        .overview-items {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.25rem;

            .overview-item {
                padding: 0.5rem 0.75rem;
                background-color: var(--color-gray-100);
                border-radius: 4px;
                flex: 1;
                min-width: fit-content;

                &-label {
                    font-size: var(--font-size-text5);
                    font-weight: 500;
                    line-height: 1.5em;
                    color: var(--color-gray-600);
                }

                &-value {
                    font-size: var(--font-size-text3);
                    line-height: 1.5em;
                    font-weight: 700;
                    color: var(--color-gray-800);
                }
            }
        }

        .division-level-tabs {
            align-self: center;
            margin-bottom: 1rem;
            margin-top: 0.25rem;

            .tab-header-item {
                padding: 0.67em 1.5em;
                font-size: var(--font-size-text5);
            }
        }

        .project-data-list {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            gap: 1rem;

            .project-data-item {
                display: flex;
                justify-content: space-between;
                gap: 0.5rem;

                &-label {
                    font-size: var(--font-size-text4);
                    line-height: 1.57em;
                    color: var(--color-gray-800);
                }

                &-value {
                    font-size: var(--font-size-text4);
                    font-weight: 700;
                    line-height: 1.57em;
                    color: var(--color-gray-800);
                }
            }

            .info-message {
                font-size: var(--font-size-text4);
                color: var(--color-gray-700);
            }
        }

        &-item-list {
            flex-flow: wrap;
            gap: 0.5rem;
            max-width: fit-content;
        }

        &-checklist {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        &-chart {
            height: 120px;
            width: 100%;
            margin-bottom: 0;

            tspan {
                font-size: 0.625rem;
                color: var(--color-gray-700);
                font-weight: 600;
            }
        }
    }
}
