.container {
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-300);
    box-shadow: var(--box-shadow-xs);
    border-radius: 4px;
    width: max-content;
    position: relative;

    &:hover {
        background-color: var(--color-gray-50);
    }

    .left-icon {
        margin-left: 0.57rem;
        color: var(--color-gray-500);
    }

    .select {
        padding: 0.5rem 0;
        flex: 1;

        .select-control {
            border: none;
            border-radius: 0;
            padding: 0 0.5rem;
            color: var(--color-gray-900);
            min-width: 7em;
            max-width: 15rem;
            white-space: nowrap;

            div,
            input {
                font-size: var(--font-size-text4);
                font-weight: 500;
                color: var(--color-gray-500);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            svg {
                height: var(--font-size-text1);
                width: var(--font-size-text1);
            }

            &-filled {
                div,
                input {
                    color: var(--color-gray-900);
                }
            }
        }

        @media screen and (min-width: 480px) {
            &:not(:last-child) {
                .select-control {
                    border-right: 1px solid var(--color-gray-300);
                }
            }
        }

        .options-wrapper {
            width: fit-content;
            background-color: var(--color-white);

            div {
                font-size: var(--font-size-text4);
            }

            .option-item {
                font-size: var(--font-size-text4);
                max-width: 15rem;
            }
        }
    }
}

.select-option-style {
    max-width: min(45vw, 30rem);
    min-width: 16rem;
}
