.priority-indicator-input-group {
    padding: 1rem 1rem 0;
    background-color: var(--color-gray-50);
    border: 1px solid var(--color-gray-200);
    border-radius: 4px;
    margin-bottom: 1.25rem;

    .priority-indicator-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .priority-indicator-title {
            font-size: var(--font-size-text4);
            line-height: 1.57em;
            font-weight: 700;
            color: var(--color-gray-800);
            margin-bottom: 0.75rem;
        }

        .remove-icon {
            color: var(--color-gray-500);
            cursor: pointer;

            &:hover {
                color: var(--color-gray-600);
            }
        }
    }
}

.tabs-header-container {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 1rem;

    .tabs-header {
        display: flex;
        overflow-x: unset;
        margin-right: 1rem;
        min-width: max-content;
        background-color: unset;

        .header-tab-item {
            margin-right: 0.5rem;
            background-color: var(--color-white);
            color: var(--color-gray-800);
            padding: 0.75rem 2.25rem;
            border: 1px solid var(--color-gray-300);
            border-radius: 52px;
            transition: border-color 0.3s ease;
            font-size: var(--font-size-text3);
            font-weight: 600;
            line-height: 1.5em;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .remove-icon {
                font-size: var(--font-size-text1);
            }

            &-active {
                border-color: var(--color-primary-600);
                color: var(--color-primary-600);
            }

            &:hover:not(.header-tab-item-active) {
                border-color: var(--color-gray-400);
            }
        }
    }

    .add-button {
        display: flex;
        align-items: center;
        color: var(--color-gray-500);
        gap: 0.5rem;
        transition: 0.3s ease-out;
        cursor: pointer;
        min-width: max-content;
        font-size: var(--font-size-text3);
        font-weight: 600;
        line-height: 1.5em;

        &:hover {
            color: var(--color-gray-600);
        }
    }
}

.form-container {
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-sm);
    border-radius: 8px;
    padding: 1.25rem 2rem 2.5rem;

    .form-section {
        &:not(:last-child) {
            margin-bottom: 2.5rem;
        }

        .form-section-title {
            border-bottom: 1px solid var(--color-gray-200);
            padding: 0.75rem 0;
            margin-bottom: 1rem;
            color: var(--color-gray-600);
            font-size: var(--font-size-text3);
            line-height: 1.5em;
            text-transform: uppercase;
        }

        .input-label {
            font-size: var(--font-size-text4);
            font-weight: 500;
            line-height: 1.57;
            text-align: left;
            color: var(--color-gray-600);
            margin-bottom: 0.25em;
        }

        .select-input {
            width: 100%;
            margin-bottom: 1.25em;
        }

        .textarea {
            padding: 0.625em 1em;
            font-size: var(--font-size-text3);
            line-height: 1.5em;
            color: var(--color-gray-900);
            width: 100%;
            border: 1px solid var(--color-border);
            background-color: inherit;
            border-radius: var(--border-radius-small);

            &::placeholder {
                color: var(--color-gray-400);
            }

            &:-ms-input-placeholder {
                color: var(--color-gray-400);
            }

            &::-ms-input-placeholder {
                color: var(--color-gray-400);
            }

            &:focus:not(:disabled),
            &:hover:not(:disabled) {
                border: 1px solid var(--color-input-outline);
                outline-color: var(--color-input-outline);
            }
        }
    }
}

.input-spacing {
    margin-bottom: 1.25em;
}

.location-inputs {
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    .location-input {
        flex: 1;
    }
}

.targets-container {
    padding: 1.5rem 1.25rem;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-50);
    border-radius: 4px;

    .targets-table-title {
        font-size: var(--font-size-text4);
        line-height: 1.57em;
        font-weight: 700;
        color: var(--color-gray-800);
        margin-bottom: 0.75rem;
    }

    .add-target-button {
        margin-top: 1rem;
    }
}
