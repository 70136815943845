.modal {
    border-radius: 8px;
    width: 41rem;
    max-width: 90vw;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-xl);
    padding: 1.25rem 1rem;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        padding: 0 0.25rem;

        .close-icon {
            color: var(--color-gray-400);
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
                color: var(--color-gray-500);
            }
        }

        .title {
            font-weight: 700;
            font-size: var(--font-size-text1);
            line-height: 1.5em;
            color: var(--color-gray-900);
        }
    }

    .report-form {
        display: flex;
        flex-direction: column;
        padding: 0 0.25rem;

        .form-content {
            max-height: 75vh;
            overflow-y: auto;

            .input-label {
                font-size: var(--font-size-text4);
                font-weight: 500;
                line-height: 1.57;
                text-align: left;
                color: var(--color-gray-600);
                margin-bottom: 0.25em;
            }
        }

        .submit-button {
            margin-top: 1rem;
            align-self: flex-end;
        }
    }
}

.input-spacing {
    margin-bottom: 1.25em;
}

.date-input-container {
    display: flex;
    flex-direction: column;

    .date-input {
        font-size: var(--font-size-text3);
        padding: 0.625em 1em;
    }
}

.input-info-text {
    font-weight: 600;
}
