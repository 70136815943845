.input-container {
    margin-bottom: 1.25rem;

    .input-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .custom-checkbox-container {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            margin-bottom: 0.75em;

            .custom-label {
                font-size: var(--font-size-text4);
            }
        }
    }

    .custom-input-enabled {
        background-color: var(--color-white);
    }

    .select {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;
        min-width: max-content;
        color: var(--color-gray-900);
        font-size: var(--font-size-text3);
        background-color: var(--color-white);
        border-radius: 0.25em;

        &-disabled {
            background-color: var(--color-gray-50);
            filter: unset;
        }

        .options-wrapper {
            background-color: var(--color-white);
        }

        .select-control {
            background-color: var(--color-neutral-50);
            padding: 0.625rem 1rem;
            cursor: pointer;

            :global {
                .select-value {
                    max-width: 32rem;
                    margin-right: auto;
                    overflow: hidden;
                    white-space: nowrap;

                    @media screen and (max-width: 1199px) {
                        max-width: 40vw;
                    }

                    @media screen and (max-width: 1024px) {
                        max-width: 55vw;
                    }
                }
            }

            input {
                font-size: var(--font-size-text3);
            }
        }
    }

    .label {
        font-size: var(--font-size-text4);
        font-weight: 500;
        line-height: 1.57;
        text-align: left;
        color: var(--color-gray-600);
        margin-bottom: 0.25em;
    }
}

.select-option-style {
    padding: 0.75em 0.5em;
    max-width: 25rem;

    .option-item-style {
        border-radius: 0.25em;
        margin-bottom: 0.25em;
    }
}
